import {
  ReservationStatusInput,
  ReservationUpdate,
} from "@relay-generated/ReservationUpdateMutation.graphql";
import { ReservationInsert } from "@relay-generated/ReservationInsertMutation.graphql";
import {
  reservationSchemaFactory,
  reservationUpdateSchemaFactory,
} from "./reservationSchema";
import { ReservationDetailEnumsFragment$data } from "@relay-generated/ReservationDetailEnumsFragment.graphql";
import { ReservationDetailFragment$data } from "@relay-generated/ReservationDetailFragment.graphql";
import { InferType } from "yup";
import { compareDayjs } from "h11-client-component-lib";
import { determineGuestChildCategory } from "./reservationUtils";
import {
  extractNewItems,
  extractUpdatedItems,
  filterExistingIds,
} from "./cudUtils";

// type Nullable<T> = {
//   [P in keyof T]: T[P] | null;
// };
//
// export type ReservationData = NonNullable<
//   ReservationDetailFragment$data["reservationGet"]
// >;
//
// // IMPORTANT: All the *FormData interface must not be partial, because we are using them in useForm hook
//
// export type ReservationFormData = {
//   uid?: ReservationUpdate["uid"] | null;
//   header: ReservationUpdate["header"] & {
//     // TODO Pryč až bude v API
//     status: string;
//   };
//   groups: RoomGroupFormData[];
//
//   // TODO možná úplně pryč z FormData? To se nikdy upravovat nebude
//   checkedInRooms?: NonNullable<
//     ReservationDetailFragment$data["reservationGet"]
//   >["checkedInRooms"];
// };
//
// export type RoomGroupFormData = Nullable<Omit<GroupRoomsInsert, "rooms">> & {
//   rooms: RoomFormData[];
// }; /*Omit<
//   NonNullable<
//     ReservationDetailFragment$data["reservationGet"]
//   >["groupRooms"][number],
//   "rooms" | "checkinDate" | "checkoutDate" | "roomTypeId"
// > & {
//   rooms: RoomFormData[];
//   checkinDate: string | undefined;
//   checkoutDate: string | undefined;
//   roomTypeId: number | undefined;
// };*/
//
// export type RoomFormData = Nullable<
//   Omit<GroupedReservationRoomInsert & GroupedReservationRoomUpdate, "guests">
// > & {
//   guests: GuestFormData[];
// }; /*Omit<
//   NonNullable<
//     ReservationUpdate["reservationGet"]
//   >["groupRooms"][number]["rooms"][number],
//   "guests"
// > & {
//   guests: GuestFormData[];
// } & {
//   // FIXME tohle je velmi špatně - mělo by se řešit tak, že formdata budou vždy insert/update,
//   //  tedy to, co chodí do API, ale spousta věcí ve formuláři bude vycházet z dat, které načtu při zobrazení stránky
// //
//   uid: string;
// };*/
//
// export type GuestFormData = Nullable<
//   ReservationGuestInsert &
//     ReservationGuestUpdate & {
//       // FIXME email zatím není v API
//       email?: string;
//     }
// >; /*NonNullable<
//   NonNullable<
//     NonNullable<ReservationData["groupRooms"]>[number]["rooms"]
//   >[number]["guests"]
// >[number] & {
//   // FIXME tohle je velmi špatně - mělo by se řešit tak, že formdata budou vždy insert/update,
//   //  tedy to, co chodí do API, ale spousta věcí ve formuláři bude vycházet z dat, které načtu při zobrazení stránky
// //
//   uid: string;
//   // FIXME email zatím není v API
//   email?: string | null;
// };*/
//
// export type HighlightedRoomAndGuestsTableItem = GuestFormData & {
//   roomUid?: string;
//   firstNameHighlighted: ReactNode;
//   lastNameHighlighted: ReactNode;
// };
//
export type ReservationEnums = ReservationDetailEnumsFragment$data;
export type RoomTypeData = NonNullable<ReservationEnums["roomTypes"]>[number];
export type RoomData = NonNullable<ReservationEnums["rooms"]>[number];
export type SourceData = NonNullable<ReservationEnums["sources"]>[number];
export type OriginData = NonNullable<ReservationEnums["origins"]>[number];
export type SaleCodeData = NonNullable<ReservationEnums["saleCodes"]>[number];
export type RateCategoryData = NonNullable<
  ReservationEnums["rateCategories"]
>[number];
export type PaymentMethodData = NonNullable<
  ReservationEnums["paymentMethods"]
>[number];
//
// export type ChildCategoryData = NonNullable<
//   ReservationEnums["childCategories"]
// >[number];
//
// export const reservationSchema = yup.object({
//   uid: yup.string(),
//   header: yup.object({
//     note: yup.string(),
//     status: yup.string(), // TODO enum
//   }),
// });

// TODO obecně
export type DeepNullable<T> = T extends (infer U)[]
  ? U extends object
    ? DeepNullable<U>[]
    : U[]
  : T extends object // Jestli je to objekt (ne pole), zanoříme se dál
    ? { [K in keyof T]: DeepNullable<T[K]> }
    : T | null;

export type ValidReservationFormData = InferType<
  ReturnType<typeof reservationSchemaFactory>
>;

export type ValidReservationUpdateFormData = InferType<
  ReturnType<typeof reservationUpdateSchemaFactory>
>;

export type ReservationFormData = DeepNullable<ValidReservationFormData>;

export type ReservationMainFormData = ReservationFormData["main"];

export type RoomGroupFormData = NonNullable<
  NonNullable<ReservationMainFormData["groups"]>
>[number];
export type RoomFormData = NonNullable<
  NonNullable<RoomGroupFormData>["rooms"]
>[number];
export type GuestFormData = NonNullable<
  NonNullable<RoomFormData>["guests"]
>[number];

export type ReservationTracesFormData = ReservationFormData["traces"];

export type TraceData = ReservationTracesFormData[number];

// FIXME proboha nějak zobecnit, bude se to dělat všude
export function translateValidFormDataToReservationUpdate({
  groupsDeleted,
  ...data
}: ValidReservationUpdateFormData["main"]): ReservationUpdate {
  const { checkedInRooms: _, groups, header, ...dataRest } = data;
  return {
    ...dataRest,
    header: {
      ...header,
      status: header.status as ReservationStatusInput,
    },
    groupRooms: {
      create: extractNewItems(groups ?? [], groupsDeleted, "uid").map(
        ({ roomsDeleted, ...group }) => ({
          ...group,
          rooms: extractNewItems(
            group.rooms,
            roomsDeleted,
            "reservationRoomUid",
          ).map(({ guestsDeleted, ...room }) => ({
            ...room,
            reservationRoomUid: undefined, // Deletion of internal UID for new items
            guests: extractNewItems(
              // Index needs to be checked before extracting new items to take all guests changed / unchanged into account
              room.guests.map((g, index) => ({
                ...g,
                mainGuest: index === 0,
              })),
              guestsDeleted,
              "uid",
            ),
          })),
        }),
      ),
      update: extractUpdatedItems(data.groups, groupsDeleted, "uid").map(
        ({ roomsDeleted, ...group }) => ({
          ...group,
          rooms: {
            create: extractNewItems(
              group.rooms,
              roomsDeleted,
              "reservationRoomUid",
            ).map(({ guestsDeleted, ...room }) => ({
              ...room,
              reservationRoomUid: undefined, // Deletion of internal UID for new items
              guests: extractNewItems(
                room.guests.map((g, index) => ({
                  ...g,
                  mainGuest: index === 0,
                })),
                guestsDeleted,
                "uid",
              ),
            })),
            update: extractUpdatedItems(
              group.rooms,
              roomsDeleted,
              "reservationRoomUid",
            ).map(({ guestsDeleted, ...room }) => {
              return {
                ...room,
                guests: {
                  create: extractNewItems(
                    room.guests.map((g, index) => ({
                      ...g,
                      mainGuest: index === 0,
                    })),
                    guestsDeleted,
                    "uid",
                  ),
                  update: extractUpdatedItems(
                    room.guests.map((g, index) => ({
                      ...g,
                      mainGuest: index === 0,
                    })),
                    guestsDeleted,
                    "uid",
                  ),
                  delete: filterExistingIds(guestsDeleted),
                },
              };
            }),
            delete: filterExistingIds(roomsDeleted),
          },
        }),
      ),
      delete: filterExistingIds(groupsDeleted),
    },
  };
}

export function translateValidFormDataToInsert(
  data: ValidReservationFormData["main"],
): ReservationInsert {
  return {
    header: {
      ...data.header,
      status: data.header.status as ReservationStatusInput,
    },
    groupRooms: data.groups.map(group => ({
      ...group,
      uid: undefined, // Deletion of internal UID for new items
      rooms: group.rooms.map(room => ({
        ...room,
        reservationRoomUid: undefined, // Deletion of internal UID for new items
        guests: room.guests.map(guest => ({
          ...guest,
          uid: undefined, // Deletion of internal UID for new items
        })),
      })),
    })),
    rooms: [], // TODO Vyhodit, až nebude v API
  };
}

export function translateDetailToFormData(
  reservation: NonNullable<ReservationDetailFragment$data["reservation"]>,
  traces: NonNullable<ReservationDetailFragment$data["traces"]>,
): ReservationFormData {
  return {
    main: {
      // FIXME v API co nejvíc sjednotit objekt pro update/insert a query, abych to nemusel takhle blbě mapovat
      uid: reservation.uid,
      header: {
        // TODO jak může být header undefined?
        ...reservation.header!,
        // TODO opravit až budou spravený překlepy v API
        status:
          reservation.header!.status === "cancelled" // Shouldn't ever happen -> reservation detail should not be displayable for cancelled reservation
            ? null
            : reservation.header!.status !== "%future added value"
              ? reservation.header!.status
              : null,
        tags: [...(reservation.header!.tags ?? [])],
      },
      groups: reservation.groupRooms
        .toSorted((a, b) => {
          let c = compareDayjs(a.checkinDate, b.checkinDate);
          if (c === 0) {
            c = compareDayjs(a.checkoutDate, b.checkoutDate);
          }
          return c;
        })
        .map(g => ({
          ...g,
          rooms: g.rooms.map(r => ({
            ...r,
            reservationRoomUid: r.uid,
            guests: r.guests
              .toSorted((a, b) => {
                // Sort by r.mainGuestUid === a/b.uid, then by childCategoryId, when both are the same, sort by lastName, firstName
                let c: number;
                if (r.mainGuestUid === a.uid) {
                  c = -1;
                } else if (r.mainGuestUid === b.uid) {
                  c = 1;
                } else {
                  c =
                    (determineGuestChildCategory(
                      a.birthDate,
                      a.childCategoryId,
                    ) ?? -1) -
                    (determineGuestChildCategory(
                      b.birthDate,
                      b.childCategoryId,
                    ) ?? -1);
                }

                if (c === 0) {
                  const lastNameA = a.lastName ?? "";
                  const lastNameB = b.lastName ?? "";
                  const firstNameA = a.firstName ?? "";
                  const firstNameB = b.firstName ?? "";

                  c = lastNameA.localeCompare(lastNameB);
                  if (c === 0) {
                    c = firstNameA.localeCompare(firstNameB);
                  }
                }

                return c;
              })
              .map(g => ({
                ...g,
                loyaltyProgram: g.loyaltyProgram ?? false,
                tags: [...(g.tags ?? [])],
              })),
            tags: [...(r.tags ?? [])],
          })),
          discountXForY: [...(g.discountXForY ?? [])],
        })),
      checkedInRooms: reservation.checkedInRooms,
      groupsDeleted: [],
    },
    traces: traces.map(({ _id, ...t }) => ({
      ...t,
      id: _id,
    })),
  };
}

export const reservationStatusesInputsFactory = (t: (key: string) => string) =>
  [
    {
      id: "waiting",
      label: t("waiting"),
    },
    {
      id: "unconfirmed",
      label: t("unconfirmed"),
    },
    {
      id: "tentative",
      label: t("tentative"),
    },
    {
      id: "fix",
      label: t("fix"),
    },
  ] satisfies {
    id: ReservationStatusInput;
    label: string;
  }[];
