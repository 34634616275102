import {
  FieldAlign,
  FlowLayout,
  Tag,
  Tags,
  Tooltipped,
  useTranslation,
} from "h11-client-component-lib";
import {
  ReservationFormData,
  reservationStatusesInputsFactory,
} from "../../reservationData";
import { useReservationContext } from "../../ReservationFormContext";
import { FormSwitchField } from "../../../to_lib/FormSwitchField";
import { useMemo } from "react";
import { Plus } from "react-bootstrap-icons";
import { FormAutocompleteField } from "../../../to_lib/dropdown/FormAutocompleteField";
import { useFormContext } from "react-hook-form";

export function OverviewPanel() {
  const { t } = useTranslation();
  const {
    enums: { sources, origins, paymentMethods, saleCodes, rateCategories },
  } = useReservationContext();

  const reservationStatusesInputs = useMemo(
    () => reservationStatusesInputsFactory(t),
    [t],
  );

  const paymentMethodsTranslated = useMemo(
    () =>
      paymentMethods.map(p => ({
        ...p,
        name: p.name.value ?? p.code,
      })),
    [paymentMethods],
  );

  const { control } = useFormContext<ReservationFormData>();

  return (
    <FlowLayout variant="spacious">
      <FormAutocompleteField
        control={control}
        items={sources}
        renderValue={value => value.nameHighlighted}
        renderTextValue={value => value.name}
        filterFields={["name"]}
        getId={v => v._id}
        field="main.header.sourceId"
        label={t("source")}
      />
      <FormAutocompleteField
        control={control}
        items={origins}
        renderValue={value => value.nameHighlighted}
        renderTextValue={value => value.name}
        filterFields={["name"]}
        getId={v => v._id}
        field="main.header.originId"
        label={t("origin")}
      />
      <FieldAlign>
        <FormSwitchField
          control={control}
          id="is-group-switch"
          field="main.header.isGroup"
          label={t("group")}
        />
      </FieldAlign>
      <FieldAlign>
        <FormSwitchField
          control={control}
          id="non-refundable-switch"
          field="main.header.nonRefundable"
          label={t("non_refundable_abbreviation")}
        />
      </FieldAlign>
      <FormAutocompleteField
        control={control}
        items={reservationStatusesInputs}
        renderTextValue={value => value.label}
        renderValue={value => value.labelHighlighted}
        getId={v => v.id}
        filterFields={["label"]}
        field="main.header.status"
        label={t("reservation_status")}
      />
      <FormAutocompleteField
        control={control}
        items={saleCodes}
        renderTextValue={value => value.name}
        renderValue={value => value.nameHighlighted}
        getId={v => v._id}
        filterFields={["name"]}
        field="main.header.saleCodeId"
        label={t("sale_segment")}
      />
      <FormAutocompleteField
        control={control}
        items={rateCategories}
        renderTextValue={value => value.name}
        renderValue={value => value.nameHighlighted}
        getId={v => v._id}
        filterFields={["name"]}
        field="main.header.rateCategoryId"
        label={t("tariff")}
      />
      <FormAutocompleteField
        control={control}
        items={paymentMethodsTranslated}
        // TODO nějak obecně? Jakto, že může být value i undefined nebo null?
        renderTextValue={value => value.name}
        renderValue={value => value.nameHighlighted}
        filterFields={["name"]}
        getId={v => v._id}
        field="main.header.preferredPaymentMethodId"
        label={t("expected_payment_method")}
      />
      <FieldAlign>
        <Tags>
          <Tag onDelete={() => alert("TODO")}>Tag 1</Tag>
          <Tag onDelete={() => alert("TODO")}>Tag 2</Tag>
          <Tooltipped tooltip={t("add_tag_tooltip")}>
            <div className="Tag" onClick={() => alert('"TODO')}>
              <Plus style={{ color: "white", cursor: "pointer" }} />
            </div>
          </Tooltipped>
        </Tags>
      </FieldAlign>
    </FlowLayout>
  );
}
