/**
 * @generated SignedSource<<6dd22e9c9661fbf873a2da111a82471e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TraceInsert = {
  assignedGroupUid?: string | null | undefined;
  assignedUserUid?: string | null | undefined;
  description?: string | null | undefined;
  important?: boolean;
  reservationRoomUid?: string | null | undefined;
  reservationUid?: string | null | undefined;
  solution?: string | null | undefined;
  status: string;
  targetDate: string;
  title: string;
  tracesCategoryId?: number | null | undefined;
};
export type TraceInsertMutation$variables = {
  input: TraceInsert;
};
export type TraceInsertMutation$data = {
  readonly traceInsert: {
    readonly _id: number;
  };
};
export type TraceInsertMutation = {
  response: TraceInsertMutation$data;
  variables: TraceInsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "traceInsert",
        "variableName": "input"
      }
    ],
    "concreteType": "Trace",
    "kind": "LinkedField",
    "name": "traceInsert",
    "plural": false,
    "selections": [
      {
        "alias": "_id",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TraceInsertMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TraceInsertMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "de59fce12b8870135f12da55fb3711a0",
    "id": null,
    "metadata": {},
    "name": "TraceInsertMutation",
    "operationKind": "mutation",
    "text": "mutation TraceInsertMutation(\n  $input: TraceInsert!\n) {\n  traceInsert(traceInsert: $input) {\n    _id: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "782c063159f81eca209593f6de89d7b6";

export default node;
