import {
  GuestFormData,
  ReservationFormData,
  RoomFormData,
  RoomGroupFormData,
  ValidReservationFormData,
  ValidReservationUpdateFormData,
} from "./reservationData";
import { FormProvider, useFieldArray } from "react-hook-form";
import { EditPage } from "../to_lib/EditPage";
import { ReservationHeader } from "./ReservationHeader";
import { SummaryPanel } from "./summary/SummaryPanel";
import { MainSection } from "./main/MainSection";
import { CurrentGuestsSection } from "./current_guests/CurrentGuestsSection";
import { useCallback, useEffect, useRef } from "react";
import { ReservationFormContext } from "./ReservationFormContext";
import { ReservationDetailEnumsFragment$data } from "@relay-generated/ReservationDetailEnumsFragment.graphql";
import { TermsAndRoomTypesSection } from "./terms_and_room_types/TermsAndRoomTypesSection";
import { DebugPanel } from "./debug/DebugPanel";
import { useTranslation } from "react-i18next";
import { LoadingOverlay } from "../to_lib/LoadingOverlay";
import { useApiForm } from "@shared/forms/apiFormNew";
import { BaseQuery, MutationCall, prepareApiCall } from "@comm/comm";
import { useRelayEnvironment } from "react-relay";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ObjectSchema } from "yup";
import { traceUpdateMutation } from "./graphql/TraceUpdateMutation";
import { TraceInsertMutation } from "@relay-generated/TraceInsertMutation.graphql";
import { TraceUpdateMutation } from "@relay-generated/TraceUpdateMutation.graphql";
import {
  extractNewItems,
  extractUpdatedItems,
  NEW_ID_PREFIX,
} from "./cudUtils";
import { traceInsertMutation } from "./graphql/TraceInsertMutation";

type ReservationChainResponsesType = {
  0: {
    reservationUid: string;
  };
  [x: string]: object;
};

export function ReservationForm<
  OutputDataType extends
    | ValidReservationFormData
    | ValidReservationUpdateFormData,
  MutationType extends BaseQuery,
>({
  data,
  enums,
  autoAddInitialData,
  onSuccess,
  createPrimaryCall,
  schema,
}: {
  data: ReservationFormData;
  enums: ReservationDetailEnumsFragment$data;
  autoAddInitialData?: boolean;
  onSuccess: () => void;
  createPrimaryCall: (
    environment: RelayModernEnvironment,
    data: OutputDataType,
  ) => MutationCall<MutationType, ReservationChainResponsesType>;
  schema: ObjectSchema<ReservationFormData>;
}) {
  const { t } = useTranslation();

  // Next internal sequences for use in temporary UIDs for use in react hook form and dnd-kit
  const nextNewGroupSeq = useRef(1);
  const nextNewRoomSeq = useRef(1);
  const nextNewGuestSeq = useRef(1);
  const nextNewTraceSeq = useRef(1);

  const nextGroupTempUid = useCallback(
    () => `${NEW_ID_PREFIX}${nextNewGroupSeq.current++}`,
    [],
  );

  const nextRoomTempUid = useCallback(
    () => `${NEW_ID_PREFIX}${nextNewRoomSeq.current++}`,
    [],
  );

  const nextGuestTempUid = useCallback(
    () => `${NEW_ID_PREFIX}${nextNewGuestSeq.current++}`,
    [],
  );

  const nextTraceTempId = useCallback(() => -nextNewTraceSeq.current++, []);

  const environment = useRelayEnvironment();

  const { form, handleSubmit } = useApiForm<
    ReservationFormData,
    OutputDataType,
    ReservationChainResponsesType
  >({
    defaultValues: data,
    schema: schema,
    messages: {
      success: t("reservation_saved"),
    },
    onSubmit: async values => {
      // TODO někam vedle
      let call = prepareApiCall(createPrimaryCall(environment, values));

      const newItems = extractNewItems(
        values.traces,
        values.tracesDeleted?.map(({ id }) => id),
        "id",
      );

      const updatedItems = extractUpdatedItems(
        values.traces,
        values.tracesDeleted?.map(({ id }) => id),
        "id",
      );

      // FIXME dodělat delete
      newItems.forEach(({ id, ...trace }) => {
        const callId = `trace-insert-${id}`;

        // FIXME vyřešit, co když ukládám trace k novému pokoji (který má zatím provizorní reservationRoomUid NEW-XXX. Nějaké mapování NEW-XXX na uložené ID při uložení main záznamu?

        const traceCall: MutationCall<
          TraceInsertMutation,
          ReservationChainResponsesType
        > = {
          id: callId,
          environment,
          skipPayloadMessages: true,
          mutation: traceInsertMutation,
          variables: responses => {
            return {
              input: {
                ...trace,
                reservationUid: responses[0]!.reservationUid,
              },
            };
          },
          validateAndExtract: response => {
            return !!response.traceInsert?._id;
          },
        };

        call = call.chain(traceCall);
      });

      updatedItems.forEach(trace => {
        const callId = `trace-update-${trace.id}`;

        const traceCall: MutationCall<
          TraceUpdateMutation,
          ReservationChainResponsesType
        > = {
          id: callId,
          environment,
          skipPayloadMessages: true,
          mutation: traceUpdateMutation,
          variables: {
            input: trace,
          },
          validateAndExtract: response => {
            return !!response.traceUpdate?._id;
          },
        };

        call = call.chain(traceCall);
      });

      values.tracesDeleted?.forEach(({ id }) => {
        const callId = `trace-delete-${id}`;

        const traceCall: MutationCall<
          TraceUpdateMutation,
          ReservationChainResponsesType
        > = {
          id: callId,
          environment,
          skipPayloadMessages: true,
          mutation: traceUpdateMutation,
          variables: {
            input: {
              id,
              status: "cancelled",
            },
          },
          validateAndExtract: response => {
            return !!response.traceUpdate?._id;
          },
        };

        call = call.chain(traceCall);
      });

      return await call.execute();
    },
    onSuccess: () => {
      onSuccess();
    },
  });
  const {
    control,
    setValue,
    getValues,
    formState: { isSubmitting },
    trigger,
    watch,
  } = form;

  // Trigger validation on mount
  useEffect(() => {
    trigger();
  }, [trigger]);

  const groups = useFieldArray({
    control,
    name: "main.groups",
  });

  const groupsDeleted = useFieldArray({
    control,
    name: "main.groupsDeleted",
  });

  console.debug("Reservation form render");

  const createDefaultGuest = useCallback(
    (childCategoryId: number | null): GuestFormData => {
      return {
        _updated: true,
        addressCountry: null,
        addressHn: null,
        addressStreet: null,
        addressTown: null,
        addressZip: null,
        birthCountry: null,
        birthName: null,
        birthPlace: null,
        carRegNumber: null,
        checkinDate: null,
        checkinMessage: null,
        checkoutDate: null,
        checkoutMessage: null,
        citizenshipCountry: null,
        cityTax: null,
        cityTaxExceptionId: null,
        guestCodeId: null,
        idCardNumber: null,
        idCardType: null,
        idValidFrom: null,
        idValidTo: null,
        loyaltyLevelId: null,
        profileUid: null,
        purposeOfStayCode: null,
        regCardCurrentAddress: null,
        regCardNote: null,
        tags: [],
        visum1: null,
        visum2: null,
        uid: nextGuestTempUid(),
        childCategoryId,
        loyaltyProgram: false,

        // TODO tohle se musí dořešit - mělo by to být inicializované celé! Nemělo by zde nic chybět - nemůžu si nějak pomoct schématem?
        firstName: null,
        lastName: null,
        birthDate: null,
        email: null,

        note: null,
      };
    },
    [nextGuestTempUid],
  );

  const createDefaultRoom = useCallback((): RoomFormData => {
    return {
      _updated: true,
      aboveAllocations: null,
      //accountUid: null,
      checkinMessage: null,
      checkoutMessage: null,
      country: null,
      guestsDeleted: null,
      note: null,
      paymentMethodId: null,
      plannedCheckoutDate: null,
      referenceNumber: null,
      //roomBlockNumber: null,
      roomId: null,
      roomServiceNote: null,
      tags: [],
      reservationRoomUid: nextRoomTempUid(),
      guests: [createDefaultGuest(null)],
    };
  }, [nextRoomTempUid, createDefaultGuest]);

  const addRoomGroup = useCallback(() => {
    const newGroup: RoomGroupFormData = {
      _updated: true,
      discountId: null,
      discountNominal: null,
      discountValue: null,
      discountXForY: null,
      roomsDeleted: null,
      servicePlan: null,
      uid: nextGroupTempUid(),
      checkinDate: null,
      roomTypeId: null,
      checkoutDate: null,
      rooms: [createDefaultRoom()],
    };
    groups.prepend(newGroup);
  }, [groups, createDefaultRoom]);

  const initialDataAdded = useRef(false);

  const isNew = false; //!data.uid;

  useEffect(() => {
    if (autoAddInitialData && !initialDataAdded.current) {
      addRoomGroup();
      initialDataAdded.current = true;
    }
  }, []);

  const markGroupAsChanged = useCallback(
    (groupIndex: number) => {
      const fieldName = `main.groups.${groupIndex}._updated` as const;
      if (!getValues(fieldName)) {
        setValue(fieldName, true);
        console.debug("Marked group as changed", groupIndex);
      }
    },
    [setValue, getValues],
  );

  const markRoomAsChanged = useCallback(
    (groupIndex: number, roomIndex: number) => {
      const fieldName =
        `main.groups.${groupIndex}.rooms.${roomIndex}._updated` as const;
      if (!getValues(fieldName)) {
        setValue(fieldName, true);
        console.debug("Marked room as changed", groupIndex, roomIndex);
      }
      markGroupAsChanged(groupIndex);
    },
    [setValue, getValues, markGroupAsChanged],
  );

  const markGuestAsChanged = useCallback(
    (groupIndex: number, roomIndex: number, guestIndex: number) => {
      const fieldName =
        `main.groups.${groupIndex}.rooms.${roomIndex}.guests.${guestIndex}._updated` as const;
      if (!getValues(fieldName)) {
        setValue(fieldName, true);
        console.debug(
          "Marked guest as changed",
          groupIndex,
          roomIndex,
          guestIndex,
        );
      }
      markRoomAsChanged(groupIndex, roomIndex);
    },
    [setValue, getValues, markRoomAsChanged],
  );

  // Set _updated fields when any field in the group/room/guest tree changes (except for internal fieldArrayChanges
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      /* Type is undefined when changing fieldArray, so we check it to ignore changes to arrays (i.e. deleting a group
      resulted in change to all groups because indices in group array changed) */

      if (name && type && !name.endsWith("._updated")) {
        // Extract potential indexes from field name, if present and mark the corresponding group/room/guest as updated
        const groupIndexMatch = name.match(/groups\.(\d+)\./);
        const roomIndexMatch = name.match(/groups\.(\d+)\.rooms\.(\d+)\./);
        const guestIndexMatch = name.match(
          /groups\.(\d+)\.rooms\.(\d+)\.guests\.(\d+)\./,
        );

        if (groupIndexMatch) {
          markGroupAsChanged(Number(groupIndexMatch[1]));
        }
        if (roomIndexMatch) {
          markRoomAsChanged(
            Number(roomIndexMatch[1]),
            Number(roomIndexMatch[2]),
          );
        }
        if (guestIndexMatch) {
          markGuestAsChanged(
            Number(guestIndexMatch[1]),
            Number(guestIndexMatch[2]),
            Number(guestIndexMatch[3]),
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const tracesFieldArray = useFieldArray({ control, name: "traces" });
  const tracesDeletedFieldArray = useFieldArray({
    control,
    name: "tracesDeleted",
  });

  return (
    <>
      <ReservationFormContext.Provider
        value={{
          enums,
          nextGroupTempUid,
          nextRoomTempUid,
          nextGuestTempUid,
          createDefaultGuest,
          createDefaultRoom,
          markGroupAsChanged,
          markRoomAsChanged,
          markGuestAsChanged,
          nextTraceTempId,
          tracesFieldArray,
          tracesDeletedFieldArray,
        }}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <EditPage>
              <EditPage.Header>
                <ReservationHeader />
              </EditPage.Header>
              <EditPage.Content>
                <MainSection />
                <TermsAndRoomTypesSection
                  groups={groups}
                  groupsDeleted={groupsDeleted}
                  onAddRoomGroup={addRoomGroup}
                />
                {!isNew && <CurrentGuestsSection />}
              </EditPage.Content>
              <EditPage.Summary>
                <SummaryPanel />
              </EditPage.Summary>
            </EditPage>
            <DebugPanel />
          </form>
        </FormProvider>
      </ReservationFormContext.Provider>
      {isSubmitting && <LoadingOverlay />}
    </>
  );
}
