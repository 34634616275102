import {
  Button,
  FlowGroup,
  FlowLayout,
  Icon,
  VerticalStack,
} from "h11-client-component-lib";
import { PopupField } from "../to_lib/PopupField";
import { useState } from "react";
import { Person } from "react-bootstrap-icons";
import { t } from "i18next";
import { ProfileButton } from "./ReservationHeader";
import { FormTextField } from "../to_lib/FormTextField";
import { ReservationFormData } from "./reservationData";
import { useFormContext } from "react-hook-form";

export const ReservationNameField = () => {
  const [open, setOpen] = useState(false);

  const { control } = useFormContext<ReservationFormData>();

  return (
    <PopupField onOpenChange={setOpen} open={open}>
      <PopupField.Field>
        <FormTextField
          control={control}
          id="reservation-name"
          variant="naked-big"
          fieldWidth="autosize"
          field="main.header.reservationName"
          placeholder={t("new_reservation")}
          onKeyDown={e => {
            if (e.key === "Escape") {
              setOpen(false);
              e.currentTarget.blur();
            } else if (e.key === "Enter") {
              setOpen(false);
              e.currentTarget.blur();
            }
          }}
          onFocus={() => setOpen(true)}
        />
      </PopupField.Field>
      <PopupField.Popup>
        <VerticalStack variant="compact">
          <div className="Table" style={{ overflow: "auto" }}>
            <table>
              <tbody>
                {[...Array(10)].map((_, index) => (
                  <tr key={index}>
                    <td>Dusilová</td>
                    <td>Lenka</td>
                    <td>19.03.2023</td>
                    <td>CZE</td>
                    <td>
                      <Icon icon={Person} tooltip={t("person_tooltip")} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <FlowLayout style={{ justifyContent: "space-between" }}>
            <ProfileButton />
            <FlowGroup>
              <Button variant="secondary" onClick={() => setOpen(false)}>
                {t("cancel")}
              </Button>
              <Button variant="secondary" onClick={() => alert("TODO")}>
                {t("ok")}
              </Button>
            </FlowGroup>
          </FlowLayout>
        </VerticalStack>
      </PopupField.Popup>
    </PopupField>
  );
};
