import { ReservationFormData, RoomFormData } from "../../reservationData";
import { UseFieldArrayReturn } from "react-hook-form";
import { useCallback, useMemo, useRef } from "react";
import { termsAndRoomTypesSectionHeadersWidths } from "../TermsAndRoomTypesSection";
import { determineGuestChildCategory } from "../../reservationUtils";
import {
  AutocompleteField,
  AutocompleteFieldHandle,
} from "../../../to_lib/dropdown/autocomplete/AutocompleteField";
import { useAppContext, useTranslation } from "h11-client-component-lib";
import { useGuestsModifier } from "../../reservationHooks";

// As array of objects, because autocomplete requires it for highlighting
const guestsCountOptions = [...Array(1000)].map((_, i) => ({
  num: i,
  label: i.toString(),
}));

export const AdultsCountField = ({
  groupIndex,
  rooms,
  roomsFieldArray,
}: {
  groupIndex: number;
  rooms: RoomFormData[];
  roomsFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.rooms"
  >;
}) => {
  const { t } = useTranslation();
  const { notify } = useAppContext();

  return null;

  const adultsCount = useMemo(() => {
    return rooms.reduce((acc, room) => {
      room.guests.forEach(g => {
        // adults only
        if (determineGuestChildCategory(g) === null) {
          acc += 1;
        }
      });
      return acc;
    }, 0);
  }, [rooms]);

  const guestsModifier = useGuestsModifier(groupIndex, rooms, roomsFieldArray);

  const autocompleteRef = useRef<AutocompleteFieldHandle>(null);

  const handleChange = useCallback(
    (newCount: number) => {
      const notRemovedCount = guestsModifier(null, newCount);
      if (notRemovedCount) {
        notify(
          t("some_guests_could_not_be_removed", {
            count: notRemovedCount,
          }),
          "warning",
        );
        autocompleteRef.current?.resetValue();
      }
    },
    [guestsModifier, adultsCount],
  );

  return (
    <AutocompleteField
      autocompleteRef={autocompleteRef}
      label={t("adults_count")}
      items={guestsCountOptions}
      getId={i => i.num}
      renderValue={i => i.labelHighlighted}
      renderTextValue={i => i.label}
      onChange={i => handleChange(i?.num ?? 0)}
      value={guestsCountOptions.find(i => i.num === adultsCount)}
      filterFields={["label"]}
      compareMode="startsWith"
      commitMode="on-close"
      numberOnly
      TextFieldProps={{
        fieldWidth: "autosize",
        inputStyle: { minWidth: termsAndRoomTypesSectionHeadersWidths[3] - 38 },
      }}
    />
  );
};
