import { ReservationFormData } from "../../reservationData";
import { useFormContext, useWatch } from "react-hook-form";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import * as React from "react";
import { useMemo } from "react";
import {
  CaretRightFill,
  ChatSquareText,
  GripVertical,
  Person,
  Trash,
} from "react-bootstrap-icons";
import {
  Child,
  Fab,
  IconButton,
  Tooltipped,
  useTranslation,
} from "h11-client-component-lib";
import { clsx } from "clsx";
import { FormTextField } from "../../../to_lib/FormTextField";
import { FormDateField } from "../../../to_lib/FormDateField";
import { determineGuestChildCategory } from "../../reservationUtils";
import { useReservationContext } from "../../ReservationFormContext";
import dayjs from "dayjs";
import { FormAutocompleteField } from "../../../to_lib/dropdown/FormAutocompleteField";

// TODO
const citizenshipCountryModel = [
  {
    id: "cs",
    name: "CZ",
  },
  {
    id: "en",
    name: "EN",
  },
];

export function GuestRow({
  groupIndex,
  roomIndex,
  guestIndex,
  onDelete,
  forDrag,
  main,
}: {
  groupIndex: number;
  roomIndex: number;
  guestIndex: number;
  onDelete?: () => void;
  forDrag?: boolean;
  main: boolean;
}) {
  const { t, tp } = useTranslation();
  const reservationContext = useReservationContext();
  const { control } = useFormContext<ReservationFormData>();
  const room = useWatch({
    control,
    name: `main.groups.${groupIndex}.rooms.${roomIndex}`,
    exact: true,
  });
  const guest = useWatch({
    control,
    name: `main.groups.${groupIndex}.rooms.${roomIndex}.guests.${guestIndex}`,
  });

  console.debug("GuestRow render");

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef: setDraggableRef,
  } = useDraggable({
    id: room.reservationRoomUid + "/" + guest.uid,
  });

  const { isOver, setNodeRef: setDroppableRef } = useDroppable({
    id: room.reservationRoomUid + "/" + guest.uid,
  });

  const note = useWatch({
    control,
    name: `main.groups.${groupIndex}.rooms.${roomIndex}.guests.${guestIndex}.note`,
  });

  const guestIconInfo = useMemo(() => {
    const childCategoryId = determineGuestChildCategory(guest);

    // TODO dummy
    const categories = [
      ...reservationContext.enums.childCategories.map(c => ({
        id: c._id,
        name: c.name,
      })),
      {
        id: 1,
        name: "0 - 3",
      },
      {
        id: 2,
        name: "4 - 11",
      },
      {
        id: 3,
        name: "12 - 17",
      },
    ];

    const childCategory = categories.find(c => c.id === childCategoryId);
    return childCategoryId === null
      ? {
          Icon: Person,
          tooltip: tp("adults", 1),
        }
      : {
          Icon: Child,
          tooltip: childCategory
            ? t("child_with_category", {
                category: childCategory.name,
              })
            : t("child"),
        };
  }, [guest]);

  return (
    <tr
      ref={setDroppableRef}
      style={{
        visibility: isDragging ? "hidden" : "visible",
      }}
      className={clsx("GuestRow", {
        "for-drag": forDrag,
        "is-dragging": isDragging,
        "is-over": isOver,
        main,
      })}>
      <td
        {...listeners}
        {...attributes}
        ref={setDraggableRef}
        className="drag-handle"
        style={{ padding: 0 }}
        tabIndex={-1}>
        <div className="drop-pointer">
          <CaretRightFill />
        </div>
        <Tooltipped
          tooltip={forDrag || isDragging ? undefined : t("move_guest_tooltip")}>
          <div style={{ padding: 8 }}>
            <GripVertical />
          </div>
        </Tooltipped>
      </td>
      <td>
        <IconButton
          icon={guestIconInfo.Icon}
          tooltip={guestIconInfo.tooltip}
          tabIndex={-1}
        />
      </td>
      <td>
        <FormTextField
          control={control}
          field={`main.groups.${groupIndex}.rooms.${roomIndex}.guests.${guestIndex}.lastName`}
          variant="inline"
          className="last-name"
        />
      </td>
      <td>
        <FormTextField
          control={control}
          field={`main.groups.${groupIndex}.rooms.${roomIndex}.guests.${guestIndex}.firstName`}
          variant="inline"
          className="first-name"
        />
      </td>
      {!forDrag && (
        <>
          <td>
            <FormTextField
              control={control}
              field={`main.groups.${groupIndex}.rooms.${roomIndex}.guests.${guestIndex}.email`}
              variant="inline"
            />
          </td>
          <td>
            <FormDateField
              control={control}
              max={dayjs().subtract(1, "day")}
              field={`main.groups.${groupIndex}.rooms.${roomIndex}.guests.${guestIndex}.birthDate`}
              variant="inline"
            />
          </td>
          <td>
            <FormAutocompleteField
              control={control}
              items={citizenshipCountryModel}
              getId={i => i.id}
              renderValue={i => i.nameHighlighted}
              renderTextValue={i => i.name}
              field={`main.groups.${groupIndex}.rooms.${roomIndex}.guests.${guestIndex}.citizenshipCountry`}
              filterFields={["name"]}
              TextFieldProps={{
                variant: "inline",
              }}
            />
          </td>
          <td>
            {note && (
              <Tooltipped tooltip={note}>
                <ChatSquareText />
              </Tooltipped>
            )}
          </td>
          <td>{/*mistni poplatek*/}</td>
          <td>
            <IconButton
              tabIndex={-1}
              onClick={() => {
                alert("TODO");
              }}
              tooltip={t("edit_guests_f_and_b_tooltip")}
              icon={Fab}
            />
          </td>
          <td>
            <IconButton
              tabIndex={-1}
              onClick={onDelete}
              tooltip={t("delete_guest_tooltip")}
              icon={Trash}
            />
          </td>
        </>
      )}
    </tr>
  );
}
