import { graphql } from "react-relay";

export const reservationDetailFragment = graphql`
  fragment ReservationDetailFragment on Query
  @argumentDefinitions(uid: { type: "UUID!" }) {
    reservation: reservationGet(reservationUid: $uid) {
      uid
      header {
        accountUid
        arrivalTime
        autoNote
        cancellationPolicyId
        cancellationPolicyText
        cancelledAt
        cancelledByUid
        checkinMessage
        checkoutMessage
        confirmationDate
        country
        createdAt
        createdByUid
        groupUid
        guestCodeId
        hotelUid
        isGroup
        new
        nonRefundable
        note
        orderAddress
        orderContact
        orderEmail
        orderName
        orderNumber
        orderPhoneNumber
        originId
        parentGroupUid
        preferredPaymentMethodId
        profileUid
        rateCategoryId
        referenceCode
        referenceNumber
        reservationName
        reservationNumber
        reservationUid
        roomServiceNote
        saleCodeId
        sourceId
        status
        statusChangedAt
        statusChangedBy
        tags
        updatedAt
        updatedByUid
      }
      checkedInRooms {
        roomId
        guests {
          uid
          firstName
          lastName
          birthDate
          citizenshipCountry
          childCategoryId
          checkinDate
          note
        }
      }
      groupRooms {
        uid
        roomTypeId
        checkinDate
        checkoutDate
        discountId
        checkinDate
        checkoutDate
        discountId
        discountNominal
        discountValue
        discountXForY
        fandbPlanServices {
          ...FandbPlanServiceFragment
        }
        pricePlanServices {
          _id: id
          cityTaxInPrice
          fandbInPrice
          fandbPackageId
          packages {
            chargeTarget
            note
            chargeType
            title
            description
            amount
            packageId
            targetDate
          }
          priceListId
          priceMasks {
            _id: id
            resday
            price
            chargeTarget
          }
        }
        roomTypeId
        servicePlanServices {
          _id: id
          items {
            id
            price
            validTo
            chargeType
            complementaryServiceId
            currencyId
            rasterId
            validFrom
          }
        }
        uid
        rooms {
          uid
          roomId
          mainGuestUid
          guests {
            uid
            firstName
            lastName
            email
            birthDate
            citizenshipCountry
            childCategoryId
            note
            loyaltyProgram
            addressCountry
            addressHn
            addressStreet
            addressTown
            addressZip
            birthCountry
            birthDate
            birthName
            birthPlace
            carRegNumber
            checkinDate
            checkinMessage
            checkoutDate
            checkoutMessage
            childCategoryId
            citizenshipCountry
            cityTax
            cityTaxExceptionId
            email
            fandbPlanServices {
              ...FandbPlanServiceFragment
            }
            firstName
            guestCodeId
            hotelUid
            idCardNumber
            idCardType
            idValidFrom
            idValidTo
            lastName
            loyaltyLevelId
            loyaltyProgram
            nameTitleAfter
            nameTitleBefore
            note
            phoneNumber
            plannedCheckoutDate
            profileUid
            purposeOfStayCode
            regCardCurrentAddress
            regCardNote
            reservationRoomUid
            status
            statusChangedBy
            tags
            uid
            visum1
            visum2
          }
          aboveAllocations
          accountUid
          checkinMessage
          checkoutMessage
          country
          hotelUid
          linkGroupUid
          note
          paymentMethodId
          plannedCheckoutDate
          referenceNumber
          reservationUid
          roomBlockNumber
          roomId
          roomServiceNote
          tags
        }
      }
    }

    # TODO pravděpodobně ne "open", ale nějak nesmazané
    traces: traceFilteredList(reservationUid: $uid, status: "open") {
      _id: id
      title
      targetDate
      important
      reservationRoomUid
      status
      description
      solution
      createdAt
      createdBy
      assignedGroupUid
      assignedUserUid
      tracesCategoryId
      updatedAt
      updatedBy
    }
  }
`;
