import { useFormContext, useWatch } from "react-hook-form";
import { ReservationFormData } from "../../reservationData";
import {
  BaseSelectComponent,
  BaseSelectComponentProps,
  IconButton,
  useTranslation,
} from "h11-client-component-lib";
import "./FbTable.scss";
import { useReservationGroupDates } from "../../reservationHooks";
import { Pencil, PlusCircle } from "react-bootstrap-icons";
import { forwardRef, ReactNode, useMemo } from "react";
import { clsx } from "clsx";

export const DUMMY_FB = ["Snídaně", "Oběd", "Večeře", "Ostatní"];

export const FbTable = ({ groupIndex }: { groupIndex: number }) => {
  // Ordinary table is used, because Table component would be a overhead
  // TODO implement ordinary table also as a component? i. e. Table and rename the current Table to DataTable?

  const { t } = useTranslation();
  const { control } = useFormContext<ReservationFormData>();
  const group = useWatch({ control, name: `main.groups.${groupIndex}` });

  const dates = useReservationGroupDates(group);

  return (
    <div className="Table FbTable">
      <table>
        <thead>
          <tr>
            <th>
              <IconButton
                variant="primary"
                tooltip={t("edit_f_and_b_tooltip")}
                icon={Pencil}
              />
            </th>
            {dates.map(d => (
              <th key={d.unix()}>{d.format("dd DD.MM.")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {DUMMY_FB.map(s => (
            <tr key={s}>
              <td>{s}</td>
              {dates.map((d, index) => (
                <td key={d.unix()}>
                  <FbSelectComponent
                    value={index % 2 ? s[0] : undefined}
                    onChange={() => {}}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

type FbSelectComponentProps = Omit<
  BaseSelectComponentProps<string, ReactNode>,
  | "itemIdExtractor"
  | "items"
  | "toggleComponent"
  | "renderValue"
  | "onChange"
  | "value"
  | "multiSelect"
  | "selectAllLabel"
> & {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
};

function FbSelectComponent(props: FbSelectComponentProps) {
  const { value, onChange } = props;

  const IconToggle = useMemo(() => {
    // eslint-disable-next-line
    const Comp = forwardRef<HTMLButtonElement, any>(
      // eslint-disable-next-line
      (bootstrapProps: any, ref) => (
        <IconButton
          ref={ref}
          {...bootstrapProps}
          variant="primary"
          className={clsx(bootstrapProps.className)}>
          {value ? value : <PlusCircle />}
        </IconButton>
      ),
    );
    Comp.displayName = "IconToggle";
    return Comp;
  }, [value]);

  return (
    <BaseSelectComponent
      multiSelect={false}
      value={value}
      onChange={v => {
        onChange(v);
      }}
      toggleComponent={IconToggle}
      itemIdExtractor={v => v}
      renderValue={v => v}
      items={DUMMY_FB}
    />
  );
}
