import { ItemsMenu } from "../ItemsMenu";
import {
  ReactNode,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { BetterDropdown, BetterDropdownHandle } from "../BetterDropdown";
import { InputFieldDropdownToggle } from "../InputFieldDropdownToggle";
import {
  BasicElementProps,
  Highlighted,
  separateBasicProps,
  StringKeyOfValueType,
  TextFieldProps,
  useTranslation,
} from "h11-client-component-lib";
import "./AutocompleteField.scss";
import { useAutocomplete, UseAutocompleteProps } from "./useAutocomplete";

export interface AutocompleteFieldHandle {
  resetValue: () => void;
}

export interface AutocompleteFieldProps<ItemType extends object>
  extends BasicElementProps,
    UseAutocompleteProps<ItemType> {
  label?: string;
  getId: (v: ItemType) => string | number;
  renderValue: (
    value: ItemType &
      Highlighted<
        ItemType,
        StringKeyOfValueType<ItemType, string | undefined | null>
      >,
  ) => ReactNode;
  TextFieldProps?: Partial<TextFieldProps>;
  ItemsMenuProps?: BasicElementProps;
  onDropdownClosed?: () => void;

  // Instead of ref, because of generics
  autocompleteRef?: RefObject<AutocompleteFieldHandle>;

  // If on-match the onChange is called right when the user input matches any value. On-close, when the dropdown closes
  commitMode?: "on-match" | "on-close";
}

export const AutocompleteField = <ItemType extends object>(
  props: AutocompleteFieldProps<ItemType>,
) => {
  const [
    basicProps,
    {
      label,
      items,
      value,
      onChange,
      renderTextValue,
      filterFields,
      TextFieldProps,
      ItemsMenuProps,
      getId,
      renderValue,
      onDropdownClosed,
      numberOnly,
      compareMode = "includes",
      commitMode = "on-match",
      autocompleteRef,
      ...restProps
    },
  ] = separateBasicProps(props, "AutocompleteField");

  const handleChange = (newValue: ItemType | undefined) => {
    if (commitMode === "on-match") {
      if ((newValue && getId(newValue)) !== (value && getId(value))) {
        // TODO nemělo by tohle být nějak už v useAutocomplete?
        onChange(newValue);
      }
    } else {
      setInternalValue(newValue);
    }
  };

  const {
    itemsHighlighted,
    inputValue,
    setInputValue,
    setFilterAndHighlight,
    onConfirm,
    resetInputValue,
    menuRef,
  } = useAutocomplete<ItemType>({
    value,
    onChange: handleChange,
    items,
    filterFields,
    compareMode,
    numberOnly,
    renderTextValue,
  });

  const { t } = useTranslation();

  /** This needs to exist to cover the case, where the input is changed back to the same value (i.e. when the new value is invalid and value stays the same).
   * We need to reset internalValue and inputValue to the former one.
   * It isn't covered by the dependency on value, because it stays the same.
   */
  useImperativeHandle(autocompleteRef, () => ({
    resetValue: () => {
      resetInputValue();
      setInternalValue(value);
    },
  }));

  const [open, setOpen] = useState(false);

  const dropdownRef = useRef<BetterDropdownHandle>(null);

  // This is used to store the value when commitMode is on-close
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  // This serves the purpose so onDropdownClosed is called only when the dropdown is really closed and not when onDropdownClosed changes
  const oldOpen = useRef(open);

  useEffect(() => {
    menuRef.current?.scrollToSelected();
  }, [internalValue]);

  useEffect(() => {
    if (oldOpen.current && !open) {
      if (commitMode === "on-close") {
        onChange(internalValue);
      }
      if (onDropdownClosed) {
        onDropdownClosed();
      }
    }
    oldOpen.current = open;
  }, [open, onDropdownClosed, onChange]);

  useEffect(() => {
    if (open) {
      menuRef.current?.scrollToSelected();
    }
  }, [open]);

  const [inputFocused, setInputFocused] = useState(false);

  const invalidValue = inputValue && !value && !inputFocused;

  return (
    <BetterDropdown
      {...basicProps}
      {...restProps}
      ref={dropdownRef}
      open={open}
      onChange={newOpen => {
        if (!open && newOpen) {
          setFilterAndHighlight(false);
        }
        setOpen(newOpen);
      }}
      control={({ ref }) => (
        <InputFieldDropdownToggle
          label={label}
          ref={ref}
          value={inputValue}
          selectAllOnFocus
          numberOnly={numberOnly}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          onEnter={e => {
            e.preventDefault();
            const matchedCount = onConfirm();
            setOpen(matchedCount > 1);
          }}
          onChange={a => {
            setInputValue(a);
            setOpen(true);
          }}
          TextFieldProps={{
            ...TextFieldProps,
            error: !!invalidValue || TextFieldProps?.error,
            helperText: invalidValue
              ? t("invalid_value")
              : TextFieldProps?.helperText,
          }}
        />
      )}
      menu={
        <ItemsMenu
          listRef={menuRef}
          getId={getId}
          renderValue={i => renderValue(i)}
          items={itemsHighlighted}
          selectedItemId={internalValue ? getId(internalValue) : undefined}
          // TODO při enteru focus na první položku. Pokud je jedna rovnou vybrat.
          onItemSelected={a => {
            handleChange(a);
            dropdownRef.current?.focusInput();
            setTimeout(() => {
              setOpen(false);
              setFilterAndHighlight(false);
            });
          }}
          {...ItemsMenuProps}
        />
      }
    />
  );
};
