import { graphql } from "react-relay";

export const reservationDetailEnumsFragment = graphql`
  fragment ReservationDetailEnumsFragment on Query {
    roomTypes: spaceTypeList(spaceCategory: ROOM) {
      ... on RoomType {
        _id: id
        code
        beds
        name {
          # TODO Neměl by tady být string pro konkrétní jazyk dle hlavičky lang?
          translation(lang: "cs")
        }
      }
    }
    rooms: spaceList {
      ... on Room {
        _id: id
        code
        name
      }
    }
    sources: reservationSourceList {
      _id: id
      code
      name
    }
    origins: reservationOriginList {
      _id: id
      code
      name
    }
    paymentMethods: paymentMethodList {
      _id: id
      code
      name {
        value
      }
    }
    childCategories: childCategoryList {
      _id: id
      name
      ageFrom
      ageTo
    }
    priceLists: pricelistList {
      _id: id
      code
      name
    }
    cancellationPolicies: cancellationPolicyList {
      _id: id
      name
    }
    saleCodes: saleCodeList {
      _id: id
      code
      name
    }
    rateCategories: rateCategoryList {
      _id: id
      code
      name
    }
  }
`;
