import "./ItemsMenu.scss";
import {
  ComponentItem,
  ComponentItemsList,
  ComponentItemsListProps,
  ItemsList,
  ItemsListProps,
} from "./ItemsList";
import { separateBasicProps } from "h11-client-component-lib";

export interface ItemsMenuProps<ItemType extends object>
  extends ItemsListProps<ItemType> {}

export const ItemsMenu = <ItemType extends object>(
  props: ItemsMenuProps<ItemType>,
) => {
  const [basicProps, restProps] = separateBasicProps(props, "ItemsMenu");
  return (
    <div {...basicProps}>
      <ItemsList {...restProps} />
    </div>
  );
};

export interface ComponentItemsMenuProps extends ComponentItemsListProps {}

export const ComponentItemsMenu = (props: ComponentItemsMenuProps) => {
  const [basicProps, restProps] = separateBasicProps(props, "ItemsMenu");
  return (
    <div {...basicProps}>
      <ComponentItemsList {...restProps} />
    </div>
  );
};

export const ComponentMenuItem = ComponentItem;
