/**
 * @generated SignedSource<<d614b46c4d11d3e6c9aa2391c9bb9b17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TraceUpdate = {
  assignedGroupUid?: string | null | undefined;
  assignedUserUid?: string | null | undefined;
  description?: string | null | undefined;
  id: number;
  important?: boolean | null | undefined;
  reservationRoomUid?: string | null | undefined;
  reservationUid?: string | null | undefined;
  solution?: string | null | undefined;
  status?: string | null | undefined;
  targetDate?: string | null | undefined;
  title?: string | null | undefined;
  tracesCategoryId?: number | null | undefined;
};
export type TraceUpdateMutation$variables = {
  input: TraceUpdate;
};
export type TraceUpdateMutation$data = {
  readonly traceUpdate: {
    readonly _id: number;
  } | null | undefined;
};
export type TraceUpdateMutation = {
  response: TraceUpdateMutation$data;
  variables: TraceUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "traceUpdate",
        "variableName": "input"
      }
    ],
    "concreteType": "Trace",
    "kind": "LinkedField",
    "name": "traceUpdate",
    "plural": false,
    "selections": [
      {
        "alias": "_id",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TraceUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TraceUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "87123bed2e24b73475fd1afa20443298",
    "id": null,
    "metadata": {},
    "name": "TraceUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation TraceUpdateMutation(\n  $input: TraceUpdate!\n) {\n  traceUpdate(traceUpdate: $input) {\n    _id: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae01d38713a642aac65ac110a7675d7c";

export default node;
