/**
 * @generated SignedSource<<f65ecafbbb19205eb5b66da790ddf705>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReservationDetailEnumsFragment$data = {
  readonly cancellationPolicies: ReadonlyArray<{
    readonly _id: number;
    readonly name: string;
  }>;
  readonly childCategories: ReadonlyArray<{
    readonly _id: number;
    readonly ageFrom: number | null | undefined;
    readonly ageTo: number | null | undefined;
    readonly name: string;
  }>;
  readonly origins: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: string;
  }>;
  readonly paymentMethods: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: {
      readonly value: string | null | undefined;
    };
  }>;
  readonly priceLists: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: string;
  }>;
  readonly rateCategories: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: string;
  }>;
  readonly roomTypes: ReadonlyArray<{
    readonly _id?: number;
    readonly beds?: number;
    readonly code?: string;
    readonly name?: {
      readonly translation: string | null | undefined;
    } | null | undefined;
  }>;
  readonly rooms: ReadonlyArray<{
    readonly _id?: number;
    readonly code?: string;
    readonly name?: string | null | undefined;
  }>;
  readonly saleCodes: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: string;
  }>;
  readonly sources: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "ReservationDetailEnumsFragment";
};
export type ReservationDetailEnumsFragment$key = {
  readonly " $data"?: ReservationDetailEnumsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReservationDetailEnumsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": "_id",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReservationDetailEnumsFragment",
  "selections": [
    {
      "alias": "roomTypes",
      "args": [
        {
          "kind": "Literal",
          "name": "spaceCategory",
          "value": "ROOM"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "spaceTypeList",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "beds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TString100",
              "kind": "LinkedField",
              "name": "name",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "lang",
                      "value": "cs"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "translation",
                  "storageKey": "translation(lang:\"cs\")"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RoomType",
          "abstractKey": null
        }
      ],
      "storageKey": "spaceTypeList(spaceCategory:\"ROOM\")"
    },
    {
      "alias": "rooms",
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "spaceList",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "Room",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "sources",
      "args": null,
      "concreteType": "ReservationSource",
      "kind": "LinkedField",
      "name": "reservationSourceList",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": "origins",
      "args": null,
      "concreteType": "ReservationOrigin",
      "kind": "LinkedField",
      "name": "reservationOriginList",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": "paymentMethods",
      "args": null,
      "concreteType": "PaymentMethod",
      "kind": "LinkedField",
      "name": "paymentMethodList",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TString100",
          "kind": "LinkedField",
          "name": "name",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "childCategories",
      "args": null,
      "concreteType": "ChildCategory",
      "kind": "LinkedField",
      "name": "childCategoryList",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ageFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ageTo",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "priceLists",
      "args": null,
      "concreteType": "PriceList",
      "kind": "LinkedField",
      "name": "pricelistList",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": "cancellationPolicies",
      "args": null,
      "concreteType": "CancellationPolicy",
      "kind": "LinkedField",
      "name": "cancellationPolicyList",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "saleCodes",
      "args": null,
      "concreteType": "SaleCode",
      "kind": "LinkedField",
      "name": "saleCodeList",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": "rateCategories",
      "args": null,
      "concreteType": "RateCategory",
      "kind": "LinkedField",
      "name": "rateCategoryList",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "39c82d859344c300241ca3a134d3f17b";

export default node;
