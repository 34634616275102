import { Loading } from "h11-client-component-lib";
import "./LoadingOverlay.scss";

export const LoadingOverlay = () => {
  return (
    <div className="LoadingOverlay">
      <Loading size={300} />
    </div>
  );
};
