import { Controller } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import {
  AutocompleteField,
  AutocompleteFieldProps,
} from "./autocomplete/AutocompleteField";
import { useCommonFieldProps } from "../formHooks";
import { FormFieldProps } from "../FormField";

export type FormAutocompleteFieldProps<
  ItemType extends object,
  TFieldValues extends FieldValues,
> = Omit<AutocompleteFieldProps<ItemType>, "value" | "name" | "onChange"> &
  FormFieldProps<TFieldValues>;

export function FormAutocompleteField<
  ItemType extends object,
  TFieldValues extends FieldValues,
>({
  field,
  control,
  ...props
}: FormAutocompleteFieldProps<ItemType, TFieldValues>) {
  const { commonFieldProps } = useCommonFieldProps(control, field);

  return (
    <Controller
      name={field}
      control={control}
      render={({ field: fieldObj }) => (
        <AutocompleteField
          {...props}
          value={
            props.items.find(i => i && props.getId(i) === fieldObj.value) ??
            undefined
          }
          onChange={value =>
            fieldObj.onChange(
              value
                ? props.getId(value)
                : null /*Undefined can't be here, because it looks like RHF would put original value there*/,
            )
          }
          TextFieldProps={{ ...props.TextFieldProps, ...commonFieldProps }}
        />
      )}
    />
  );
}
