import { graphql } from "react-relay";

export const traceUpdateMutation = graphql`
  mutation TraceUpdateMutation($input: TraceUpdate!) {
    traceUpdate(traceUpdate: $input) {
      _id: id

      # TODO vrátit až bude v API
      # ...PayloadMessages_interface
    }
  }
`;
