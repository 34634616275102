import {
  Button,
  Dropdown,
  FlowGroup,
  FlowLayout,
  IconButton,
  useTranslation,
} from "h11-client-component-lib";
import { CaretDownFill, DashSquare, Person } from "react-bootstrap-icons";
import { ReservationNameField } from "./ReservationNameField";

export const ProfileButton = () => {
  const { t } = useTranslation();
  return (
    <IconButton
      variant="outlined"
      size={32}
      tooltip={t("profile_tooltip")}
      onClick={() => alert("TODO")}
      icon={Person}
    />
  );
};

export const ReservationHeader = () => {
  const { t } = useTranslation();

  return (
    <FlowLayout
      className="ReservationHeader"
      style={{ alignItems: "center", justifyContent: "flex-end" }}>
      <div style={{ position: "relative" }}>
        <ReservationNameField />
        <div className="unread-badge" />
      </div>
      <ProfileButton />
      {/*<FlowGroup>
        <Button variant="secondary" onClick={() => alert("TODO")}>
          {t("accommodation")}
        </Button>
        <Button variant="secondary" onClick={() => alert("TODO")}>
          MICE
        </Button>
        <Button variant="secondary" onClick={() => alert("TODO")}>
          Leisure
        </Button>
      </FlowGroup>*/}
      <IconButton variant="outlined" size={32} tooltip={t("color_tooltip")}>
        <div
          style={{
            height: 16,
            width: 16,
            borderRadius: 4,
            background: "rgba(75, 206, 151, 1)",
          }}
        />
      </IconButton>
      <div style={{ flex: "1" }} />
      <FlowGroup style={{ alignItems: "center" }}>
        {/*FIXME vlastní komponenta SplitButton*/}
        <div style={{ display: "flex" }} className="SplitButton">
          <Button
            type="submit" /*TODO možná imperativně, protože jak budu řešit submit + dodatečná akce?*/
          >
            {t("save_reservation")}
          </Button>
          <Dropdown>
            <Dropdown.Control>
              <IconButton
                variant="outlined-primary"
                size={32}
                noTooltip
                icon={CaretDownFill}
              />
            </Dropdown.Control>
            <Dropdown.Menu>
              <Dropdown.Item onSelect={() => alert("TODO")}>
                {t("save_and_send_confirmation")}
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => alert("TODO")}>
                {t("save_and_issue_proforma")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Button onClick={() => alert("TODO")} variant="secondary">
          {t("close")}
        </Button>
        <IconButton
          onClick={() => alert("TODO")}
          variant="filled"
          size={32}
          tooltip={t("minimize_tooltip")}>
          <DashSquare />
        </IconButton>
      </FlowGroup>
    </FlowLayout>
  );
};
