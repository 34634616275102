import {
  dayjsSafe,
  FlowGroup,
  FlowLayout,
  Icon,
  IconButton,
  RoomNumber,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import {
  Bell,
  ExclamationTriangle,
  Eye,
  Pencil,
  Trash,
} from "react-bootstrap-icons";
import { ReservationFormData, TraceData } from "../../reservationData";
import { useReservationContext } from "../../ReservationFormContext";
import { useFormContext, useWatch } from "react-hook-form";
import { useMemo } from "react";

export const Trace = ({
  trace: { important, title, targetDate, reservationRoomUid },
  onDelete,
  onEdit,
}: {
  trace: TraceData;
  onDelete: () => void;
  onEdit: () => void;
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ReservationFormData>();
  const reservationContext = useReservationContext();
  const groups = useWatch({ control, name: "main.groups" });
  const traceReservationRoom = useMemo(
    () =>
      groups
        .flatMap(g => g.rooms)
        .find(r => r.reservationRoomUid === reservationRoomUid),
    [groups, reservationRoomUid],
  );

  const traceRoomNumber = useMemo(
    () =>
      traceReservationRoom
        ? (reservationContext.enums.rooms.find(
            r => r._id === traceReservationRoom?.roomId,
          )?.name ?? "???")
        : null,
    [reservationContext.enums.rooms, traceReservationRoom],
  );

  return (
    <VerticalStack className="Trace" variant="compact">
      <RoomNumber>
        {traceRoomNumber ? traceRoomNumber : t("for_all_reservations_rooms")}
      </RoomNumber>
      <div>{title}</div>
      <FlowLayout style={{ alignItems: "flex-end" }}>
        <FlowLayout style={{ flex: "1" }} className="flags">
          <FlowGroup style={{ alignItems: "center" }}>
            {important && (
              <Icon
                icon={ExclamationTriangle}
                className="warning"
                tooltip={t("trace_warning_tooltip")}
              />
            )}
            <Icon icon={Eye} tooltip={t("trace_show_when_tooltip")} />
            {t("on_checkin")}
          </FlowGroup>
          {targetDate && (
            <FlowGroup style={{ alignItems: "center" }}>
              <Icon icon={Bell} tooltip={t("trace_alert_tooltip")} />
              <div>{dayjsSafe(targetDate).format("DD.MM.YY HH:mm")}</div>
            </FlowGroup>
          )}
          <FlowGroup>
            <div>{t("intended_for")}: FO, HSK</div>
          </FlowGroup>
        </FlowLayout>
        <FlowGroup>
          <IconButton
            variant="filled"
            size={32}
            onClick={onEdit}
            tooltip={t("edit_trace_tooltip")}
            icon={Pencil}
          />
          <IconButton
            variant="filled"
            size={32}
            onClick={onDelete}
            tooltip={t("delete_trace_tooltip")}
            icon={Trash}
          />
        </FlowGroup>
      </FlowLayout>
    </VerticalStack>
  );
};
