import i18n, { t } from "i18next";
import { initReactI18next } from "react-i18next";
import cs from "./dict/cs.json";
import en from "./dict/en.json";
import { DEV } from "@params";
import * as yup from "yup";

function cleanup(translations: object) {
  return Object.fromEntries(Object.entries(translations).filter(e => e[1]));
}

i18n
  // TODO pak případně toto, pokud to nebude čistě na uživatelském nastavení
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: DEV,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      cs: {
        translation: cleanup(cs),
      },
      en: {
        translation: cleanup(en),
      },
    },
  });

// messages are functions, so it gets translation when needed, otherwise it doesn't work in this setup
yup.setLocale({
  // FIXME
  mixed: {
    required: () => t("this_field_is_required"),
  },
  string: {
    email: () => t("email_is_not_valid"),
  },
});
