/**
 * @generated SignedSource<<f304547131a5e51d40b7e4a94ff68cab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Order = "ASC" | "DESC" | "%future added value";
export type ReservationGroups = "GROUP" | "SINGLE" | "%future added value";
export type ReservationPrepayment = "NO_PREPAYMENT" | "PAID" | "PROFORMA" | "PROFORMA_OVERDUE" | "UNPAID" | "%future added value";
export type ReservationRefundable = "NON_REFUNDABLE" | "REFUNDABLE" | "%future added value";
export type ReservationRooming = "EMPTY" | "FILLED" | "INCOMPLETE" | "%future added value";
export type ReservationStatus = "CANCELLED" | "FIX" | "TENTATIVE" | "UNCONFIRMED" | "WAITING" | "%future added value";
export type ReservationsOverviewColumns = "cancelledAt" | "checkIn" | "checkOut" | "country" | "createdAt" | "group" | "name" | "nonRefundable" | "note" | "number" | "optionDate" | "orderNumber" | "partner" | "paymentMethod" | "prepayment" | "roomType" | "rooming" | "source" | "status" | "transactionNumber" | "%future added value";
export type ReservationsOverviewFilters = {
  author?: ReadonlyArray<string> | null | undefined;
  cancelledAt?: any | null | undefined;
  checkIn?: any | null | undefined;
  checkOut?: any | null | undefined;
  createdAt?: any | null | undefined;
  group?: ReadonlyArray<ReservationGroups> | null | undefined;
  guestCode?: ReadonlyArray<number> | null | undefined;
  nonRefundable?: ReadonlyArray<ReservationRefundable> | null | undefined;
  optionDate?: any | null | undefined;
  partner?: ReadonlyArray<number> | null | undefined;
  paymentMethod?: ReadonlyArray<number> | null | undefined;
  prepayment?: ReadonlyArray<ReservationPrepayment> | null | undefined;
  roomType?: ReadonlyArray<number> | null | undefined;
  rooming?: ReadonlyArray<ReservationRooming> | null | undefined;
  salesCode?: ReadonlyArray<number> | null | undefined;
  search?: string | null | undefined;
  source?: ReadonlyArray<number> | null | undefined;
  status?: ReadonlyArray<ReservationStatus> | null | undefined;
  tags?: ReadonlyArray<number> | null | undefined;
};
export type ReservationsOverviewOrderBy = {
  column: ReservationsOverviewColumns;
  order: Order;
};
export type ReservationsQuery$variables = {
  after?: string | null | undefined;
  filters?: ReservationsOverviewFilters | null | undefined;
  first?: number | null | undefined;
  orderBy?: ReservationsOverviewOrderBy | null | undefined;
};
export type ReservationsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ReservationsFragment">;
};
export type ReservationsQuery = {
  response: ReservationsQuery$data;
  variables: ReservationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": 50,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v5 = [
  (v1/*: any*/),
  {
    "fields": [
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReservationsQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ReservationsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReservationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ReservationsOverviewHoresConnection",
        "kind": "LinkedField",
        "name": "reservationsOverviewList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReservationsOverviewEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReservationsOverview",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reservationUid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "group",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tags",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roomType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roomCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "guestsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "checkIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "checkOut",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "source",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prepayment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rooming",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orderNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "optionDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "guestCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salesCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nonRefundable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "author",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cancelledAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "transactionNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentMethod",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "attachment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "filters"
        ],
        "handle": "connection",
        "key": "ReservationsConnectionFragment_reservationsOverviewList",
        "kind": "LinkedHandle",
        "name": "reservationsOverviewList"
      }
    ]
  },
  "params": {
    "cacheID": "817a21afa8e5496a20dee336589c6677",
    "id": null,
    "metadata": {},
    "name": "ReservationsQuery",
    "operationKind": "query",
    "text": "query ReservationsQuery(\n  $after: String\n  $filters: ReservationsOverviewFilters\n  $first: Int = 50\n  $orderBy: ReservationsOverviewOrderBy\n) {\n  ...ReservationsFragment_2cgv2A\n}\n\nfragment ReservationsFragment_2cgv2A on Query {\n  reservationsOverviewList(first: $first, after: $after, filters: {filters: $filters, orderBy: $orderBy}) {\n    edges {\n      cursor\n      node {\n        id\n        reservationUid\n        status\n        group\n        tags\n        number\n        name\n        roomType\n        roomCount\n        guestsCount\n        checkIn\n        checkOut\n        source\n        price\n        prepayment\n        rooming\n        orderNumber\n        country\n        optionDate\n        guestCode\n        salesCode\n        note\n        nonRefundable\n        author\n        createdAt\n        cancelledAt\n        transactionNumber\n        paymentMethod\n        attachment\n        __typename\n      }\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "1540e2eb0e0b1605973e9174d41a78af";

export default node;
