import {
  Button,
  dayjsSafe,
  Dialog,
  FieldAlign,
  HorizontalStack,
  SelectField,
  Switch,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import { Resolver, useForm, useWatch } from "react-hook-form";
import { TraceFormData } from "./TracesPanel";
import { FormTextField } from "../../../to_lib/FormTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { traceSchemaFactory } from "../../reservationSchema";
import { useEffect } from "react";
import { DeepNullable } from "../../reservationData";
import { FormDateField } from "../../../to_lib/FormDateField";
import { FormSwitchField } from "../../../to_lib/FormSwitchField";

export const TraceDialog = ({
  open,
  onClose,
  onSubmit,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: TraceFormData) => void;
  defaultValues: DeepNullable<TraceFormData>;
}) => {
  const { t } = useTranslation();

  const traceForm = useForm<
    DeepNullable<TraceFormData>,
    undefined,
    TraceFormData
  >({
    resolver: yupResolver(traceSchemaFactory(t)) as unknown as Resolver<
      DeepNullable<TraceFormData>
    >,
    defaultValues,
  });

  useEffect(() => {
    if (open) {
      traceForm.reset(defaultValues);
    }
  }, [open, defaultValues]);

  const solved =
    useWatch({ control: traceForm.control, name: "status" }) === "closed";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={defaultValues.id ? t("edit_trace") : t("new_trace")}
      actions={
        <>
          <Button onClick={onClose} variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            onClick={e => {
              traceForm.handleSubmit(onSubmit)(e);
            }}
            variant="secondary">
            {t("ok")}
          </Button>
        </>
      }>
      <VerticalStack>
        <HorizontalStack style={{ width: 700 }}>
          <VerticalStack style={{ flex: "1" }}>
            <SelectField
              label={t("trace_template") + " X"}
              value=""
              items={["trace1", "trace2", "trace3"]}
              valueToString={item => item}
              itemIdExtractor={item => item}
              onChange={() => {}}
            />
            <FormTextField
              control={traceForm.control}
              field="title"
              label={t("trace_text")}
              multiline
            />
            <FormTextField
              control={traceForm.control}
              field="description"
              label={t("trace_description")}
              multiline
            />
          </VerticalStack>
          <VerticalStack style={{ flex: "1" }}>
            <FieldAlign>
              <Switch
                id={`trace-${defaultValues.id}-all-rooms`}
                checked
                onChange={s => {}}
                label={t("for_all_reservation_rooms") + " X"}
              />
            </FieldAlign>
            <SelectField
              label={t("for_room") + " X"}
              disabled
              value={[]}
              multiSelect
              items={["201", "202", "203"]}
              valueToString={item => item}
              itemIdExtractor={item => item}
              onChange={() => {}}
            />
            <SelectField
              label={t("show_trace") + " X"}
              value={["v okamžik příjezdu"]}
              multiSelect
              // TODO odkud brát tyto hodnoty?
              items={["dva dny před příjezdem 8:00", "v okamžik příjezdu"]}
              valueToString={item => item}
              itemIdExtractor={item => item}
              onChange={() => {}}
            />
            <FormDateField
              control={traceForm.control}
              field="targetDate"
              label={t("term")}
            />
            <FormSwitchField
              id={`trace-${defaultValues.id}-important`}
              field="important"
              control={traceForm.control}
              label={t("needs_attention")}
            />
            <Switch
              id={`trace-${defaultValues.id}-solved`}
              label={t("solved")}
              checked={solved}
              onChange={checked => {
                traceForm.setValue("status", checked ? "closed" : "open");
              }}
            />
            {solved && (
              // TODO při ukládání, pokud není solved, asi vynulovat?
              <FormTextField
                control={traceForm.control}
                field="solution"
                label={t("solved_comment")}
              />
            )}
          </VerticalStack>
        </HorizontalStack>
        {defaultValues.createdAt &&
          defaultValues.createdBy &&
          defaultValues.updatedAt &&
          defaultValues.updatedBy && (
            <VerticalStack
              variant="squished"
              style={{ fontSize: 12, marginTop: 60 }}>
              {defaultValues.updatedAt && defaultValues.updatedBy && (
                <span>
                  {t("last_edit")}
                  {": "}
                  {dayjsSafe(defaultValues.updatedAt).format(
                    "DD.MM.YYYY HH:mm",
                  )}
                  {", "}
                  {defaultValues.updatedBy} X
                </span>
              )}
              {defaultValues.createdAt && defaultValues.createdBy && (
                <span>
                  {t("created")}
                  {": "}
                  {dayjsSafe(defaultValues.createdAt).format(
                    "DD.MM.YYYY HH:mm",
                  )}
                  {", "}
                  {defaultValues.updatedBy} X
                </span>
              )}
            </VerticalStack>
          )}
      </VerticalStack>
    </Dialog>
  );
};
