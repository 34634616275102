import * as React from "react";
import {
  DateRangeField,
  DateRangeFieldProps,
  FullDateRange,
} from "h11-client-component-lib";
import {
  Control,
  Path,
  useController,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import dayjs from "dayjs";
import { useCommonFieldProps } from "./formHooks";

export type FormDateRangeFieldProps<TFieldValues extends FieldValues> = Omit<
  DateRangeFieldProps,
  "value" | "name" | "onChange"
> & {
  startField: Path<TFieldValues>;
  endField: Path<TFieldValues>;
  control: Control<TFieldValues>;
};

export function FormDateRangeField<TFieldValues extends FieldValues>({
  startField,
  endField,
  ...props
}: FormDateRangeFieldProps<TFieldValues>) {
  const { control } = useFormContext<TFieldValues>();

  const startDate = useWatch({ control, name: startField });
  const endDate = useWatch({ control, name: endField });
  const dateRange: FullDateRange | undefined =
    startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : undefined;

  const startFieldController = useController({
    name: startField,
    control,
  });

  const endFieldController = useController({
    name: endField,
    control,
  });

  const { commonFieldProps: commonStartFieldProps } = useCommonFieldProps(
    control,
    startField,
  );
  const { commonFieldProps: commonEndFieldProps } = useCommonFieldProps(
    control,
    endField,
  );

  const commonFieldProps = {
    error: commonStartFieldProps.error || commonEndFieldProps.error,
    mandatory: commonStartFieldProps.mandatory || commonEndFieldProps.mandatory,
    helperText:
      commonStartFieldProps.helperText || commonEndFieldProps.helperText,
  };

  return (
    <DateRangeField
      value={dateRange}
      onChange={v => {
        startFieldController.field.onChange(v?.[0]);
        endFieldController.field.onChange(v?.[1]);
      }}
      {...props}
      {...commonFieldProps}
    />
  );
}
