import { BetterDropdown, BetterDropdownProps } from "../BetterDropdown";
import "./AutocompleteField.scss";
import { AutocompleteList, AutocompleteListProps } from "./AutocompleteList";
import {
  BasicElementProps,
  separateBasicProps,
} from "h11-client-component-lib";
import { useState } from "react";
import "./DropdownAutocomplete.scss";

export interface DropdownAutocompleteProps<ItemType extends object>
  extends BasicElementProps,
    AutocompleteListProps<ItemType> {
  control: BetterDropdownProps["control"];
}

export const DropdownAutocomplete = <ItemType extends object>(
  props: DropdownAutocompleteProps<ItemType>,
) => {
  const [basicProps, { control, ...AutocompleteListProps }] =
    separateBasicProps(props, "DropdownAutocomplete");
  // FIXME scroll on open -> podobně jako v AutocompleteField
  // FIXME hide on confirm
  // FIXME focus input on open

  const [open, setOpen] = useState(false);

  return (
    <BetterDropdown
      {...basicProps}
      open={open}
      onChange={setOpen}
      control={control}
      menu={<AutocompleteList {...AutocompleteListProps} />}
    />
  );
};
