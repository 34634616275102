import {
  Checkbox,
  FormItem,
  IconButton,
  Radio,
  Switch,
  TextField,
} from "h11-client-component-lib";
import { useState } from "react";
import { AutocompleteField } from "./pages/reservations/to_lib/dropdown/autocomplete/AutocompleteField";
import { AutocompleteList } from "./pages/reservations/to_lib/dropdown/autocomplete/AutocompleteList";
import { DropdownAutocomplete } from "./pages/reservations/to_lib/dropdown/autocomplete/DropdownAutocomplete";
import { Bug } from "react-bootstrap-icons";

export function Showcase() {
  const [checked, setChecked] = useState(false);
  const [text, setText] = useState("");
  return (
    <div style={{ margin: 20 }}>
      <Switch id="without-label" checked={checked} onChange={setChecked} />
      <Switch
        id="with-label"
        label="Popisek"
        checked={checked}
        onChange={setChecked}
      />
      <Switch
        id="with-label-disabled"
        label="Popisek"
        disabled
        checked={checked}
        onChange={setChecked}
      />

      <Radio checked={checked} onChange={setChecked} />
      <Radio label="Popisek" checked={checked} onChange={setChecked} />
      <Radio label="Popisek" disabled checked={checked} onChange={setChecked} />

      <Checkbox checked={checked} onChange={setChecked} />
      <Checkbox label="Popisek" checked={checked} onChange={setChecked} />
      <Checkbox
        label="Popisek"
        disabled
        checked={checked}
        onChange={setChecked}
      />

      <FormItem label="Název poznámky" message={"Poznámka"}>
        <TextField value={text} onChange={setText} />
        <TextField value={text} onChange={setText} />
      </FormItem>

      <FormItem label="Název poznámky" message={"Chyba"} error>
        <TextField value={text} onChange={setText} />
        <TextField value={text} onChange={setText} />
      </FormItem>

      <FormItem label="Název poznámky" message={"Poznámka"}>
        <TextField value={text} onChange={setText} disabled />
      </FormItem>

      <FormItem label="Název poznámky" message={"Poznámka"}>
        <TextField value={text} onChange={setText} disabled />
      </FormItem>

      <FormItem label="Název poznámky" message={"Poznámka"}>
        <TextField value={text} onChange={setText} disabled />
      </FormItem>

      <Autocompletes />
    </div>
  );
}

interface ItemType {
  id: number;
  name: string;
}

const items: ItemType[] = Array.from({ length: 100 }, (_, i) => ({
  id: i,
  name: `Položka ${i}`,
}));

function Autocompletes() {
  const [selectedItem, setSelectedItem] = useState<ItemType>();

  return (
    <div style={{ display: "flex" }}>
      {/*<AutocompleteField
        items={items}
        getId={i => i.id}
        renderValue={i => <div>{i.nameHighlighted}</div>}
        renderTextValue={i => i.name}
        onChange={i => setSelectedItem(i)}
        value={selectedItem}
        filterFields={["name"]}
      />*/}
      <AutocompleteField
        items={items}
        getId={i => i.id}
        renderValue={i => <div>{i.nameHighlighted}</div>}
        renderTextValue={i => i.name}
        onChange={i => setSelectedItem(i)}
        value={selectedItem}
        filterFields={["name"]}
      />
      <AutocompleteList
        items={items}
        getId={i => i.id}
        renderValue={i => <div>{i.nameHighlighted}</div>}
        renderTextValue={i => i.name}
        onChange={i => setSelectedItem(i)}
        value={selectedItem}
        filterFields={["name"]}
        ItemsMenuProps={{
          style: { maxHeight: 200, overflow: "auto" },
        }}
      />
      <DropdownAutocomplete
        items={items}
        getId={i => i.id}
        renderValue={i => <div>{i.nameHighlighted}</div>}
        renderTextValue={i => i.name}
        onChange={i => setSelectedItem(i)}
        value={selectedItem}
        filterFields={["name"]}
        control={({ currentOpen, onOpenChange }) => (
          <IconButton
            onClick={() => onOpenChange(!currentOpen)}
            noTooltip
            icon={Bug}
          />
        )}
      />
    </div>
  );
}
