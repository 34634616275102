import { graphql } from "react-relay";

export const traceInsertMutation = graphql`
  mutation TraceInsertMutation($input: TraceInsert!) {
    traceInsert(traceInsert: $input) {
      _id: id

      # TODO vrátit až bude v API
      # ...PayloadMessages_interface
    }
  }
`;
