import { useAutocomplete } from "./useAutocomplete";
import { useRef } from "react";
import { BetterDropdownHandle } from "../BetterDropdown";
import { AutocompleteFieldProps } from "./AutocompleteField";
import "./AutocompleteField.scss";
import { separateBasicProps, TextField } from "h11-client-component-lib";
import { ItemsList } from "../ItemsList";
import "./AutocompleteList.scss";

export interface AutocompleteListProps<ItemType extends object>
  extends AutocompleteFieldProps<ItemType> {}

export const AutocompleteList = <ItemType extends object>(
  props: AutocompleteListProps<ItemType>,
) => {
  const [
    basicProps,
    {
      label,
      items, // Items separated from restProps, because of the filter
      value,
      onChange,
      renderTextValue,
      filterFields,
      TextFieldProps,
      ItemsMenuProps,
      getId,
      renderValue,
      numberOnly,
      compareMode = "includes",
    },
  ] = separateBasicProps(props, "AutocompleteList");

  const {
    itemsHighlighted,
    inputValue,
    setInputValue,
    setFilterAndHighlight,
    onConfirm,
    menuRef,
  } = useAutocomplete<ItemType>({
    value,
    onChange,
    items,
    filterFields,
    compareMode,
    numberOnly,
    renderTextValue,
  });

  const dropdownRef = useRef<BetterDropdownHandle>(null);

  return (
    <div {...basicProps}>
      <TextField
        label={label}
        value={inputValue}
        onKeyDown={e => {
          if (e.key === "Enter") {
            e.preventDefault();
            onConfirm();
          }
        }}
        onChange={a => {
          setInputValue(a);
        }}
        {...TextFieldProps}
      />
      <ItemsList
        listRef={menuRef}
        getId={getId}
        renderValue={i => renderValue(i)}
        items={itemsHighlighted}
        selectedItemId={value ? getId(value) : undefined}
        // TODO při enteru focus na první položku. Pokud je jedna rovnou vybrat.
        onItemSelected={a => {
          onChange(a);
          dropdownRef.current?.focusInput();
          setTimeout(() => {
            setFilterAndHighlight(false);
          });
        }}
        {...ItemsMenuProps}
      />
    </div>
  );
};
