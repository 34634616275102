import {
  Button,
  FlowLayout,
  Heading,
  SelectField,
  useAppContext,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import { ClipboardCheck, ClipboardPlus, Sliders } from "react-bootstrap-icons";
import { Trace } from "./Trace";
import "./index.scss";
import { DeepNullable, TraceData } from "../../reservationData";
import { useReservationContext } from "../../ReservationFormContext";
import { useCallback, useState } from "react";
import { TraceDialog } from "./TraceDialog";
import { Resolver, useForm } from "react-hook-form";
import { traceSchemaFactory } from "../../reservationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { InferType } from "yup";
import { FormTextField } from "../../../to_lib/FormTextField";
import dayjs from "dayjs";

export type TraceFormData = InferType<ReturnType<typeof traceSchemaFactory>>;

export function TracesPanel({ traces }: { traces: TraceData[] }) {
  const { t } = useTranslation();

  const { notify } = useAppContext();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editDialogDefaultValues, setEditDialogDefaultValues] =
    useState<DeepNullable<TraceFormData>>();
  const [editDialogIndex, setEditDialogIndex] = useState<number>();

  const { tracesFieldArray, tracesDeletedFieldArray, nextTraceTempId } =
    useReservationContext();

  const simpleTraceForm = useForm<
    DeepNullable<TraceFormData>,
    unknown,
    TraceFormData
  >({
    resolver: yupResolver(traceSchemaFactory(t)) as unknown as Resolver<
      DeepNullable<TraceFormData>
    >,
    defaultValues: {
      id: null,
      title: null,
      description: null,
      important: false,
      status: "open",
      reservationRoomUid: null,
      solution: null,
      assignedGroupUid: null,
      assignedUserUid: null,
      targetDate: dayjs().toISOString(), // FIXME v API povinné, v jednoduchém formuláři nepřítomné
      //tracesCategoryId: null,
    },
  });

  const createTrace = useCallback((data: TraceFormData) => {
    tracesFieldArray.prepend({
      ...data,
      id: nextTraceTempId(),
    });
    simpleTraceForm.reset();
  }, []);

  const updateTrace = useCallback((index: number, data: TraceFormData) => {
    tracesFieldArray.update(index, {
      ...data,
      _updated: true,
    });
    simpleTraceForm.reset();
  }, []);

  return (
    <>
      <FlowLayout
        variant="compact"
        style={{ flex: "1", alignItems: "stretch" }}>
        <VerticalStack style={{ flex: "1 1 200px", minWidth: 200 }}>
          <Heading icon={<ClipboardPlus />}>{t("new_trace")}</Heading>
          <SelectField
            label={t("trace_template")}
            value=""
            items={["trace1", "trace2", "trace3"]}
            valueToString={item => item}
            itemIdExtractor={item => item}
            onChange={() => {}}
          />
          <FormTextField
            label={t("trace_text")}
            control={simpleTraceForm.control}
            field="title"
            multiline
          />
          <SelectField
            // TODO
            label={t("intended_for")}
            items={["front_office", "f&b", "housekeeping", "maintenance"]}
            valueToString={i => i}
            itemIdExtractor={i => i}
            value={[]}
            onChange={() => {}}
            multiSelect
            selectAllLabel={t("all_groups")}
          />
          <FlowLayout
            variant="compact"
            style={{ justifyContent: "space-between" }}>
            <Button
              variant="secondary"
              icon={<Sliders />}
              onClick={() => {
                setEditDialogOpen(true);
                setEditDialogDefaultValues(simpleTraceForm.getValues());
                setEditDialogIndex(undefined);
              }}>
              {t("more_options")}
            </Button>
            <Button
              variant="secondary"
              onClick={e => {
                // Individual handling of submit, so the main form is not submitted
                simpleTraceForm.handleSubmit(createTrace, errors => {
                  notify(t("form_contains_errors"), "danger");
                  console.error(errors);
                })(e);
              }}>
              {t("save_trace")}
            </Button>
          </FlowLayout>
        </VerticalStack>
        <VerticalStack
          style={{ flex: "1 1 200px", minWidth: 200 }}
          variant="compact">
          <Heading icon={<ClipboardCheck />}>{t("reservation_traces")}</Heading>
          <VerticalStack variant="squished">
            {traces.map((trace, index) => (
              <Trace
                key={trace.id}
                trace={trace}
                onEdit={() => {
                  setEditDialogOpen(true);
                  setEditDialogDefaultValues(trace);
                  setEditDialogIndex(index);
                }}
                onDelete={() => {
                  tracesFieldArray.remove(index);
                  if (trace.id) {
                    tracesDeletedFieldArray.append({ id: trace.id });
                  }
                }}
              />
            ))}
          </VerticalStack>
        </VerticalStack>
      </FlowLayout>
      {editDialogDefaultValues && (
        <TraceDialog
          open={editDialogOpen}
          onClose={() => {
            setEditDialogOpen(false);
            setEditDialogIndex(undefined);
          }}
          onSubmit={data => {
            if (editDialogIndex !== undefined) {
              updateTrace(editDialogIndex, data);
            } else {
              createTrace(data);
            }
            setEditDialogOpen(false);
            setEditDialogIndex(undefined);
          }}
          defaultValues={editDialogDefaultValues}
        />
      )}
    </>
  );
}
