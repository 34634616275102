import {
  Control,
  useController,
  useFormState,
  useWatch,
} from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types/path";

/**
 * Common field props for form fields.
 * fieldProps should be passed to the TextField component as a last props {...fieldProps}/> so it should override helperText.
 * @param control
 * @param field
 */
export const useCommonFieldProps = <T extends FieldValues>(
  control: Control<T>,
  field: FieldPath<T>,
) => {
  const { submitCount } = useFormState({ control });

  const value = useWatch({ control, name: field, exact: true });
  const {
    fieldState: { error, invalid, isTouched },
  } = useController({ control, name: field });

  const mandatory = invalid && !value;
  const erroneous = invalid && (isTouched || submitCount > 0);
  const errorMessage = erroneous ? error?.message : undefined;

  const commonFieldProps = erroneous
    ? {
        error: true,
        mandatory,
        helperText: errorMessage,
      }
    : {
        error: false,
        mandatory,
      };

  return {
    commonFieldProps,
  };
};
