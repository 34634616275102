import { useFormContext, useWatch } from "react-hook-form";
import { ReservationFormData } from "../reservationData";
import "./DebugPanel.scss";
import { IconButton } from "h11-client-component-lib";
import { Bug } from "react-bootstrap-icons";
import { useState } from "react";
import { clsx } from "clsx";
import { JSONTree } from "react-json-tree";

export const DebugPanel = () => {
  const { control } = useFormContext<ReservationFormData>();

  const [open, setOpen] = useState(false);

  const values = useWatch({
    control,
  });

  return (
    <>
      <div className={clsx("DebugPanel", { open })}>
        <JSONTree data={values} />
        <IconButton
          variant="filled"
          size={32}
          icon={Bug}
          tooltip="debug"
          onClick={() => {
            setOpen(!open);
          }}
        />
      </div>
    </>
  );
};
