/**
 * @generated SignedSource<<0485b39f471bf4fb79cd3e62ebb918f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReservationsPageEnumsQuery$variables = Record<PropertyKey, never>;
export type ReservationsPageEnumsQuery$data = {
  readonly guestCodeList: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: string;
  }>;
  readonly reservationSourceList: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: string;
  }>;
  readonly reservationTagList: ReadonlyArray<{
    readonly _id: number;
    readonly color: any | null | undefined;
    readonly title: string;
  }>;
  readonly saleCodeList: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: string;
  }>;
  readonly spaceTypeList: ReadonlyArray<{
    readonly _id?: number;
    readonly code?: string;
    readonly name?: {
      readonly value: string | null | undefined;
    } | null | undefined;
  }>;
  readonly userList: ReadonlyArray<{
    readonly avatar: {
      readonly url: string;
    } | null | undefined;
    readonly firstName: string | null | undefined;
    readonly lastName: string | null | undefined;
    readonly userUid: string;
  }>;
};
export type ReservationsPageEnumsQuery = {
  response: ReservationsPageEnumsQuery$data;
  variables: ReservationsPageEnumsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "_id",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "SaleCode",
  "kind": "LinkedField",
  "name": "saleCodeList",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "GuestCode",
  "kind": "LinkedField",
  "name": "guestCodeList",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ReservationSource",
  "kind": "LinkedField",
  "name": "reservationSourceList",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TString100",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SpaceType",
  "abstractKey": "__isSpaceType"
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "userList",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userUid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Asset",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ReservationTag",
  "kind": "LinkedField",
  "name": "reservationTagList",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReservationsPageEnumsQuery",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "spaceTypeList",
        "plural": true,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReservationsPageEnumsQuery",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "spaceTypeList",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "1010816e89141508ad07e4868eabc862",
    "id": null,
    "metadata": {},
    "name": "ReservationsPageEnumsQuery",
    "operationKind": "query",
    "text": "query ReservationsPageEnumsQuery {\n  saleCodeList {\n    _id: id\n    code\n    name\n  }\n  guestCodeList {\n    _id: id\n    code\n    name\n  }\n  reservationSourceList {\n    _id: id\n    code\n    name\n  }\n  spaceTypeList {\n    __typename\n    ... on SpaceType {\n      __isSpaceType: __typename\n      _id: id\n      code\n      name {\n        value\n      }\n    }\n  }\n  userList {\n    userUid\n    firstName\n    lastName\n    avatar {\n      url\n    }\n  }\n  reservationTagList {\n    _id: id\n    title\n    color\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f73d34359d6dedbc6dfdb581806c810";

export default node;
