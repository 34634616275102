/**
 * @generated SignedSource<<3e6dc6c3fa27e5e70031dd76fa05fc2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReservationStatusInput = "fix" | "tentative" | "unconfirmed" | "waiting" | "%future added value";
export type ReservationUpdate = {
  groupRooms?: GroupRoomsCUDUpdate | null | undefined;
  header?: ReservationHeaderUpdate | null | undefined;
  uid: string;
};
export type GroupRoomsCUDUpdate = {
  create?: ReadonlyArray<GroupRoomsInsert> | null | undefined;
  delete?: ReadonlyArray<string> | null | undefined;
  update?: ReadonlyArray<GroupRoomsUpdate> | null | undefined;
};
export type GroupRoomsInsert = {
  checkinDate: string;
  checkoutDate: string;
  discountId?: number | null | undefined;
  discountNominal?: string | null | undefined;
  discountValue?: string | null | undefined;
  discountXForY?: ReadonlyArray<number> | null | undefined;
  fandbPlanService?: FandbPlanServiceInsert | null | undefined;
  pricePlanService?: ReadonlyArray<PricePlanInsert> | null | undefined;
  roomTypeId: number;
  rooms: ReadonlyArray<GroupedReservationRoomInsert>;
  servicePlan?: ReadonlyArray<ServicePlanItemInsert> | null | undefined;
};
export type FandbPlanServiceInsert = {
  chargeType?: string | null | undefined;
  fandbPackageId?: number | null | undefined;
  items?: ReadonlyArray<FandbPlanItemInsert> | null | undefined;
};
export type FandbPlanItemInsert = {
  centerId?: number | null | undefined;
  currencyId?: number | null | undefined;
  fandbCodeId: number;
  note?: string | null | undefined;
  price?: string | null | undefined;
  resday: number;
};
export type PricePlanInsert = {
  items: ReadonlyArray<PricePlanItemsInsert>;
  packages: ReadonlyArray<PricePlanPackageInsert>;
  priceMask?: ReadonlyArray<PricePlanPriceMaskInsert> | null | undefined;
  pricelistId?: number | null | undefined;
};
export type PricePlanItemsInsert = {
  amount: number;
  chargeTarget: string;
  chargeType?: string | null | undefined;
  currencyId: number;
  description?: any | null | undefined;
  discountId?: number | null | undefined;
  discountNominal?: string | null | undefined;
  discountValue?: string | null | undefined;
  finalPrice?: string | null | undefined;
  itemType: string;
  locked?: boolean | null | undefined;
  note?: any | null | undefined;
  origin: string;
  packageId?: number | null | undefined;
  rasterId?: number | null | undefined;
  targetDate: string;
  title?: string | null | undefined;
  unitPrice: string;
};
export type PricePlanPackageInsert = {
  amount?: number;
  chargeTarget: string;
  chargeType: string;
  description?: string | null | undefined;
  note?: string | null | undefined;
  packageId: number;
  targetDate: string;
  title?: string | null | undefined;
};
export type PricePlanPriceMaskInsert = {
  chargeTarget?: string;
  price: string;
  resday: number;
};
export type GroupedReservationRoomInsert = {
  aboveAllocations?: boolean | null | undefined;
  accountUid?: string | null | undefined;
  checkinMessage?: string | null | undefined;
  checkoutMessage?: string | null | undefined;
  country?: string | null | undefined;
  guests: ReadonlyArray<ReservationGuestInsert>;
  note?: string | null | undefined;
  paymentMethodId?: number | null | undefined;
  plannedCheckoutDate?: string | null | undefined;
  referenceNumber?: string | null | undefined;
  roomBlockNumber?: number | null | undefined;
  roomId?: number | null | undefined;
  roomServiceNote?: string | null | undefined;
  tags?: ReadonlyArray<number> | null | undefined;
};
export type ReservationGuestInsert = {
  addressCountry?: string | null | undefined;
  addressHn?: string | null | undefined;
  addressStreet?: string | null | undefined;
  addressTown?: string | null | undefined;
  addressZip?: string | null | undefined;
  birthCountry?: string | null | undefined;
  birthDate?: string | null | undefined;
  birthName?: string | null | undefined;
  birthPlace?: string | null | undefined;
  carRegNumber?: string | null | undefined;
  checkinDate?: string | null | undefined;
  checkinMessage?: string | null | undefined;
  checkoutDate?: string | null | undefined;
  checkoutMessage?: string | null | undefined;
  childCategoryId?: number | null | undefined;
  citizenshipCountry?: string | null | undefined;
  cityTax?: boolean | null | undefined;
  cityTaxExceptionId?: number | null | undefined;
  email?: string | null | undefined;
  fandbPlanService?: FandbPlanServiceInsert | null | undefined;
  firstName?: string | null | undefined;
  guestCodeId?: number | null | undefined;
  idCardNumber?: string | null | undefined;
  idCardType?: string | null | undefined;
  idValidFrom?: string | null | undefined;
  idValidTo?: string | null | undefined;
  lastName?: string | null | undefined;
  loyaltyLevelId?: number | null | undefined;
  loyaltyProgram?: boolean;
  mainGuest?: boolean;
  nameTitleAfter?: string | null | undefined;
  nameTitleBefore?: string | null | undefined;
  note?: string | null | undefined;
  phoneNumber?: string | null | undefined;
  profileUid?: string | null | undefined;
  purposeOfStayCode?: number | null | undefined;
  regCardCurrentAddress?: string | null | undefined;
  regCardNote?: string | null | undefined;
  tags?: ReadonlyArray<number> | null | undefined;
  visum1?: string | null | undefined;
  visum2?: string | null | undefined;
};
export type ServicePlanItemInsert = {
  chargeOnce?: boolean;
  chargeType: string;
  complementaryServiceId: number;
  price?: string | null | undefined;
  validFrom?: string | null | undefined;
  validTo?: string | null | undefined;
};
export type GroupRoomsUpdate = {
  checkinDate?: string | null | undefined;
  checkoutDate?: string | null | undefined;
  discountId?: number | null | undefined;
  discountNominal?: string | null | undefined;
  discountValue?: string | null | undefined;
  discountXForY?: ReadonlyArray<number> | null | undefined;
  fandbPlanService?: FandbPlanServiceInsert | null | undefined;
  pricePlanService?: ReadonlyArray<PricePlanInsert> | null | undefined;
  roomTypeId?: number | null | undefined;
  rooms?: GroupedReservationRoomCUDUpdate | null | undefined;
  servicePlan?: ReadonlyArray<ServicePlanItemInsert> | null | undefined;
  uid: string;
};
export type GroupedReservationRoomCUDUpdate = {
  create?: ReadonlyArray<GroupedReservationRoomInsert> | null | undefined;
  delete?: ReadonlyArray<string> | null | undefined;
  update?: ReadonlyArray<GroupedReservationRoomUpdate> | null | undefined;
};
export type GroupedReservationRoomUpdate = {
  aboveAllocations?: boolean | null | undefined;
  checkinMessage?: string | null | undefined;
  checkoutMessage?: string | null | undefined;
  country?: string | null | undefined;
  guests?: ReservationGuestCUDUpdate | null | undefined;
  note?: string | null | undefined;
  paymentMethodId?: number | null | undefined;
  plannedCheckoutDate?: string | null | undefined;
  referenceNumber?: string | null | undefined;
  reservationRoomUid: string;
  roomId?: number | null | undefined;
  roomServiceNote?: string | null | undefined;
  tags?: ReadonlyArray<number> | null | undefined;
};
export type ReservationGuestCUDUpdate = {
  create?: ReadonlyArray<ReservationGuestInsert> | null | undefined;
  delete?: ReadonlyArray<string> | null | undefined;
  update?: ReadonlyArray<ReservationGuestUpdate> | null | undefined;
};
export type ReservationGuestUpdate = {
  addressCountry?: string | null | undefined;
  addressHn?: string | null | undefined;
  addressStreet?: string | null | undefined;
  addressTown?: string | null | undefined;
  addressZip?: string | null | undefined;
  birthCountry?: string | null | undefined;
  birthDate?: string | null | undefined;
  birthName?: string | null | undefined;
  birthPlace?: string | null | undefined;
  carRegNumber?: string | null | undefined;
  checkinDate?: string | null | undefined;
  checkinMessage?: string | null | undefined;
  checkoutDate?: string | null | undefined;
  checkoutMessage?: string | null | undefined;
  childCategoryId?: number | null | undefined;
  citizenshipCountry?: string | null | undefined;
  cityTax?: boolean | null | undefined;
  cityTaxExceptionId?: number | null | undefined;
  email?: string | null | undefined;
  fandbPlanService?: FandbPlanServiceInsert | null | undefined;
  firstName?: string | null | undefined;
  guestCodeId?: number | null | undefined;
  idCardNumber?: string | null | undefined;
  idCardType?: string | null | undefined;
  idValidFrom?: string | null | undefined;
  idValidTo?: string | null | undefined;
  lastName?: string | null | undefined;
  loyaltyLevelId?: number | null | undefined;
  loyaltyProgram?: boolean | null | undefined;
  mainGuest?: boolean | null | undefined;
  nameTitleAfter?: string | null | undefined;
  nameTitleBefore?: string | null | undefined;
  note?: string | null | undefined;
  phoneNumber?: string | null | undefined;
  profileUid?: string | null | undefined;
  purposeOfStayCode?: number | null | undefined;
  regCardCurrentAddress?: string | null | undefined;
  regCardNote?: string | null | undefined;
  tags?: ReadonlyArray<number> | null | undefined;
  uid: string;
  visum1?: string | null | undefined;
  visum2?: string | null | undefined;
};
export type ReservationHeaderUpdate = {
  accountUid?: string | null | undefined;
  arrivalTime?: string | null | undefined;
  autoNote?: string | null | undefined;
  cancellationPolicyId?: number | null | undefined;
  cancellationPolicyText?: string | null | undefined;
  checkinMessage?: string | null | undefined;
  checkoutMessage?: string | null | undefined;
  confirmationDate?: string | null | undefined;
  country?: string | null | undefined;
  groupUid?: string | null | undefined;
  guestCodeId?: number | null | undefined;
  isGroup?: boolean | null | undefined;
  nonRefundable?: boolean | null | undefined;
  note?: string | null | undefined;
  orderAddress?: string | null | undefined;
  orderContact?: string | null | undefined;
  orderEmail?: string | null | undefined;
  orderName?: string | null | undefined;
  orderNumber?: string | null | undefined;
  orderPhoneNumber?: string | null | undefined;
  originId?: number | null | undefined;
  parentGroupUid?: string | null | undefined;
  preferredPaymentMethodId?: number | null | undefined;
  profileUid?: string | null | undefined;
  rateCategoryId?: number | null | undefined;
  referenceCode?: string | null | undefined;
  referenceNumber?: string | null | undefined;
  reservationName?: string | null | undefined;
  roomServiceNote?: string | null | undefined;
  saleCodeId?: number | null | undefined;
  sourceId?: number | null | undefined;
  status?: ReservationStatusInput | null | undefined;
  tags?: ReadonlyArray<number> | null | undefined;
};
export type ReservationUpdateMutation$variables = {
  input: ReservationUpdate;
};
export type ReservationUpdateMutation$data = {
  readonly reservationUpdate: {
    readonly reservation: {
      readonly uid: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"PayloadMessages_interface">;
  };
};
export type ReservationUpdateMutation = {
  response: ReservationUpdateMutation$data;
  variables: ReservationUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "reservationUpdate",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Reservation",
  "kind": "LinkedField",
  "name": "reservation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uid",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "PayloadMessages_message",
    "selections": (v3/*: any*/),
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReservationUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReservationUpdatePayload",
        "kind": "LinkedField",
        "name": "reservationUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineDataFragmentSpread",
            "name": "PayloadMessages_interface",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "warnings",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "PayloadInterface",
                "abstractKey": "__isPayloadInterface"
              }
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReservationUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReservationUpdatePayload",
        "kind": "LinkedField",
        "name": "reservationUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "warnings",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "type": "PayloadInterface",
            "abstractKey": "__isPayloadInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f84ba29a037a0d420544c359daac7dd8",
    "id": null,
    "metadata": {},
    "name": "ReservationUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ReservationUpdateMutation(\n  $input: ReservationUpdate!\n) {\n  reservationUpdate(reservationUpdate: $input) {\n    reservation {\n      uid\n    }\n    ...PayloadMessages_interface\n  }\n}\n\nfragment PayloadMessages_interface on PayloadInterface {\n  __isPayloadInterface: __typename\n  errors {\n    ...PayloadMessages_message\n  }\n  warnings {\n    ...PayloadMessages_message\n  }\n}\n\nfragment PayloadMessages_message on PayloadMessage {\n  code\n  text\n}\n"
  }
};
})();

(node as any).hash = "9e6ddcbc9f624ea1f93370708a223893";

export default node;
