import { useEffect } from "react";
import { ObjectSchema } from "yup";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { useRelayEnvironment } from "react-relay";
import { CallError, ChainResponsesTypeBase, ExecuteResult } from "@comm/comm";
import { useAppContext } from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { DefaultValues, Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export function useApiForm<
  FormDataType extends object,
  FormOutputDataType extends object,
  ChainResponsesType extends ChainResponsesTypeBase = never,
>({
  defaultValues,
  schema,
  messages,
  visible = true,
  onSubmit,
  onSuccess,
}: {
  defaultValues: DefaultValues<FormDataType>;
  schema: ObjectSchema<FormDataType>;

  // Visible acts as a dependency for form reset but also other things that makes sense only when the form is visible
  // (ie. fields error logging).
  // Default is visible.
  visible?: boolean;
  messages?: {
    success?: string;
    withWarnings?: string;
    unexpectedError?: string;
  };
  onSubmit: (
    validValues: FormOutputDataType,
    relayEnvironment: RelayModernEnvironment,
  ) => Promise<ExecuteResult<ChainResponsesType>>;
  onSuccess: (responses: Partial<ChainResponsesType>) => void;
}) {
  const { notify } = useAppContext();
  const { t } = useTranslation();
  const relayEnvironment = useRelayEnvironment();

  const handleValidSubmit = async (values: FormOutputDataType) => {
    try {
      const { warnings, responses } = await onSubmit(values, relayEnvironment);
      if (warnings) {
        notify(messages?.withWarnings ?? t("saved_with_errors"), "warning");
      } else {
        notify(messages?.success ?? t("saved"), "success");
      }

      onSuccess(responses);
    } catch (e) {
      if (e instanceof CallError) {
        if (e.type === "expected") {
          notify(e.message, "danger");
          return;
        }
      }

      console.error(e);

      notify(messages?.unexpectedError ?? t("unexpected_error"), "danger");
    }
  };

  const form = useForm<FormDataType, unknown, FormOutputDataType>({
    resolver: yupResolver(
      schema,
      {
        stripUnknown: true,
      },
      {
        mode: "async",
      },
    ) as unknown as Resolver<FormDataType>, // hacky conversion because otherwise RHF expects the resolver to infer type from schema, but we need it DeepNullable to allow empty fields
    defaultValues: defaultValues,

    // TODO Lépe onBlur? ale pole to musí respektovat a volat onBlur
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const { reset, handleSubmit } = form;

  useEffect(() => {
    if (visible) {
      reset();
      // TODO ověřit, zda reset doplní default hodnoty
    }
  }, [visible]);

  const handleSubmitInternal = handleSubmit(
    // @ts-expect-error - we know the data is of the right type - problem with handleSubmit?
    data => handleValidSubmit(data),
    e => {
      notify(t("form_contains_errors"), "danger");
      console.warn(e);
    },
  );

  return { form, handleSubmit: handleSubmitInternal };
}
