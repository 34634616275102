export interface CUDItem {
  _updated?: boolean | null | undefined;
}

export function isUpdated(item: CUDItem): boolean {
  return !!item._updated;
}

export const NEW_ID_PREFIX = "NEW-";

const isNew = (id: unknown) => {
  if (typeof id === "string") {
    return id.startsWith(NEW_ID_PREFIX);
  } else if (typeof id === "number") {
    return id < 0;
  } else {
    return !id;
  }
};

export const extractNewItems = <T extends CUDItem, K extends keyof T>(
  items: T[],
  deletedItems: T[K][] | undefined | null,
  idField: K,
) =>
  items
    // Not checking _updated, isNew id is enough
    .filter(item => /*item._updated && */ isNew(item[idField]))
    .filter(item => !(deletedItems ?? []).includes(item[idField]))
    .map(item => {
      const i = { ...item };
      // Deletion of internal UID for new items
      delete i[idField];
      // Deletion of _updated flag
      delete i._updated;
      return i;
    });

export const filterExistingIds = <T>(items?: T[] | null) =>
  items?.filter(item => !isNew(item));

// TODO dopsat komentář, proč je to přes _updated a smazané zvlášť a ne _cudOperation apod.
export const extractUpdatedItems = <T extends CUDItem, K extends keyof T>(
  items: T[],
  deletedItems: T[K][] | undefined | null,
  idField: K,
) =>
  items
    .filter(
      (item): item is T & Record<K, NonNullable<T[K]>> =>
        !!item._updated && item[idField] != null && !isNew(item[idField]),
    )
    .filter(item => !(deletedItems ?? []).includes(item[idField]))
    .map(item => {
      const i = { ...item };
      delete i._updated; // Deletion of _updated flag
      return i;
    });
