import { ReservationFormData, RoomFormData } from "../../reservationData";
import { UseFieldArrayReturn } from "react-hook-form";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { termsAndRoomTypesSectionHeadersWidths } from "../TermsAndRoomTypesSection";
import {
  Button,
  Dropdown,
  DropdownControlled,
  NumberField,
  SelectFieldToggle,
  useAppContext,
  useTranslation,
} from "h11-client-component-lib";
import { determineGuestChildCategory } from "../../reservationUtils";
import { useGuestsModifier } from "../../reservationHooks";

interface GuestsCountsFieldProps {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  category: { id: number | null; name: string };
  showLabel?: boolean;
}

const GuestsCountField = forwardRef<HTMLElement, GuestsCountsFieldProps>(
  ({ value, onChange, category, showLabel = true }, ref) => {
    const { t } = useTranslation();
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 20,
        }}>
        {showLabel && (
          <div style={{ whiteSpace: "nowrap" }}>
            {category ? category.name : t("adults")}
          </div>
        )}
        <NumberField
          ref={ref}
          value={value}
          min={0}
          max={999}
          onChange={onChange}
          variant="buttoned"
          fieldWidth={40}
          style={{ textAlign: "center" }}
        />
      </div>
    );
  },
);
GuestsCountField.displayName = "GuestsCountField";

export const ChildrenCountsField = ({
  groupIndex,
  rooms,
  roomsFieldArray,
  childCategories,
}: {
  groupIndex: number;
  rooms: RoomFormData[];
  roomsFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.rooms"
  >;
  childCategories: {
    id: number | null;
    name: string;
  }[];
}) => {
  const { t } = useTranslation();
  const { notify } = useAppContext();
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLElement>(null);

  const groupedCounts = useMemo(() => {
    return rooms.reduce(
      (acc, room) => {
        room.guests.forEach(g => {
          const category = determineGuestChildCategory(g) ?? -1;
          acc[category] = (acc[category] ?? 0) + 1;
        });
        return acc;
      },
      {} as Record<number, number>,
    );
  }, [rooms]);

  const [groupedCountsInternal, setGroupedCountsInternal] =
    useState<Record<number, number | undefined>>(groupedCounts);

  useEffect(() => {
    if (open) {
      setGroupedCountsInternal(groupedCounts);
    }
  }, [open]);

  useEffect(() => {
    setGroupedCountsInternal(groupedCounts);
  }, [groupedCounts]);

  const totalCount = useMemo(() => {
    return Object.entries(groupedCountsInternal)
      .filter(g => childCategories.some(c => (c.id ?? -1) === Number(g[0])))
      .map(g => Number(g[1]))
      .filter(g => g)
      .map(g => g!)
      .reduce((acc, count) => acc + count, 0);
  }, [groupedCountsInternal]);

  const guestsModifier = useGuestsModifier(groupIndex, rooms, roomsFieldArray);

  const commit = useCallback(() => {
    const notRemovedCount = childCategories
      .map(category =>
        guestsModifier(
          category.id,
          groupedCountsInternal[category.id ?? -1] ?? 0,
        ),
      )
      .reduce((acc, count) => acc + count, 0);
    if (notRemovedCount) {
      notify(
        t("some_guests_could_not_be_removed", {
          count: notRemovedCount,
        }),
        "warning",
      );
    }
  }, [guestsModifier, groupedCounts, groupedCountsInternal, childCategories]);

  const changeOpen = useCallback(
    (open: boolean) => {
      setOpen(open);
      if (open) {
        setTimeout(() => {
          inputRef.current?.focus();
        });
      } else {
        commit();
      }
    },
    [commit, setOpen],
  );

  return (
    <DropdownControlled open={open} onChange={changeOpen}>
      <Dropdown.Control>
        <SelectFieldToggle
          fieldWidth="autosize"
          label={t("children_count")}
          inputStyle={{
            minWidth: termsAndRoomTypesSectionHeadersWidths[4] - 38,
          }}
          value={totalCount.toString()}
        />
      </Dropdown.Control>
      <Dropdown.Menu>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: 4,
            padding: "0 16px",
            flex: "1",
          }}>
          {childCategories.map(c => (
            <GuestsCountField
              ref={inputRef}
              key={c.id}
              category={c}
              value={groupedCountsInternal[c.id ?? -1] ?? 0}
              onChange={value => {
                setGroupedCountsInternal(g => {
                  return { ...g, [c.id ?? -1]: value };
                });
              }}
              showLabel={childCategories.length > 1}
            />
          ))}
          <Button
            variant="secondary"
            style={{ marginTop: 10 }}
            onClick={() => changeOpen(false)}>
            {t("ok")}
          </Button>
        </div>
      </Dropdown.Menu>
    </DropdownControlled>
  );
};
