import {
  Button,
  useTranslation,
  VerticalStack,
} from "h11-client-component-lib";
import { PlusCircle } from "react-bootstrap-icons";
import { RoomsTable } from "./RoomsTable";
import { useReservationContext } from "../../ReservationFormContext";
import { UseFieldArrayReturn } from "react-hook-form";
import { ReservationFormData } from "../../reservationData";
import { AssignRoomsPanel } from "./AssignRoomsPanel";

export const RoomsAndGuestsPanel = ({
  groupIndex,
  roomsFieldArray,
  roomsDeletedFieldArray,
}: {
  groupIndex: number;
  roomsFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.rooms"
  >;
  roomsDeletedFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.roomsDeleted"
  >;
}) => {
  const { t } = useTranslation();
  const reservationContext = useReservationContext();
  const { createDefaultRoom } = useReservationContext();
  console.debug("RoomsAndGuestsPanel render");
  return (
    <VerticalStack style={{ flex: "1" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="secondary"
          icon={<PlusCircle />}
          onClick={() => {
            roomsFieldArray.prepend(createDefaultRoom());
            reservationContext.markGroupAsChanged(groupIndex);
          }}>
          {t("add_room")}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            alert("TODO");
          }}>
          {t("detailed_rooming")}
        </Button>
      </div>
      <RoomsTable
        groupIndex={groupIndex}
        roomsFieldArray={roomsFieldArray}
        roomsDeletedFieldArray={roomsDeletedFieldArray}
      />
      <AssignRoomsPanel groupIndex={groupIndex} />
    </VerticalStack>
  );
};
