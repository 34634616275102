import { graphql } from "react-relay";

export const reservationUpdateMutation = graphql`
  mutation ReservationUpdateMutation($input: ReservationUpdate!) {
    reservationUpdate(reservationUpdate: $input) {
      reservation {
        uid
      }
      ...PayloadMessages_interface
    }
  }
`;
