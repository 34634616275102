import { useEffect, useMemo } from "react";
import {
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import "./index.scss";
import "./to_lib.scss";
import { ReservationDetailEnumsFragment$key } from "@relay-generated/ReservationDetailEnumsFragment.graphql";
import { reservationDetailEnumsFragment } from "./graphql/ReservationDetailEnumsFragment";
import { ReservationCreateQuery } from "@relay-generated/ReservationCreateQuery.graphql";
import { reservationCreateQuery } from "./graphql/ReservationCreateQuery";
import { ReservationForm } from "./ReservationForm";
import {
  translateValidFormDataToReservationUpdate,
  ValidReservationUpdateFormData,
} from "./reservationData";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { reservationUpdateMutation } from "./graphql/ReservationUpdateMutation";
import { ReservationUpdateMutation } from "@relay-generated/ReservationUpdateMutation.graphql";
import { reservationSchemaFactory } from "./reservationSchema";

export function ReservationCreatePage() {
  const [queryRef, loadQuery] = useQueryLoader<ReservationCreateQuery>(
    reservationCreateQuery,
  );

  useEffect(() => {
    loadQuery({});
  }, []);

  return queryRef && <LoadedReservationCreatePage queryRef={queryRef} />;
}

function LoadedReservationCreatePage({
  queryRef,
}: {
  queryRef: PreloadedQuery<ReservationCreateQuery>;
}) {
  const reservationData = usePreloadedQuery(reservationCreateQuery, queryRef);

  const enums = useFragment<ReservationDetailEnumsFragment$key>(
    reservationDetailEnumsFragment,
    reservationData,
  );

  const navigate = useNavigate();
  const { t } = useTranslation();

  const schema = useMemo(() => reservationSchemaFactory(t), [t]);

  return (
    // TODO ValidReservationUpdateFormData is used here, but it should be ValidReservationFormData, after API is fixed
    <ReservationForm<ValidReservationUpdateFormData, ReservationUpdateMutation>
      data={{
        main: {
          uid: null,
          groupsDeleted: [],
          checkedInRooms: [],
          groups: [],
          header: {
            reservationName: null,
            sourceId: null,
            accountUid: null,
            checkinMessage: null,
            checkoutMessage: null,
            country: null,
            guestCodeId: null,
            note: null,
            profileUid: null,
            tags: null,
            referenceNumber: null,
            roomServiceNote: null,
            arrivalTime: null,
            autoNote: null,
            cancellationPolicyId: null,
            cancellationPolicyText: null,
            confirmationDate: null,
            groupUid: null,
            isGroup: null,
            nonRefundable: null,
            orderAddress: null,
            orderContact: null,
            orderEmail: null,
            orderName: null,
            orderNumber: null,
            orderPhoneNumber: null,
            originId: null,
            parentGroupUid: null,
            preferredPaymentMethodId: null,
            referenceCode: null,
            saleCodeId: null,
            status: null,
          },
        },
        traces: [],
      }}
      enums={enums}
      schema={schema}
      autoAddInitialData
      createPrimaryCall={(environment, formData) => {
        const data = translateValidFormDataToReservationUpdate(formData.main);
        return {
          environment,
          // FIXME insert, až bude správně v API
          mutation: reservationUpdateMutation,
          variables: {
            input: data,
          },
        };
      }}
      onSuccess={() => {
        navigate("/reservation");
      }}
    />
  );
}
