import { useEffect, useMemo } from "react";
import {
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import "./index.scss";
import "./to_lib.scss";
import { ReservationUpdateQuery } from "@relay-generated/ReservationUpdateQuery.graphql";
import { reservationUpdateQuery } from "./graphql/ReservationUpdateQuery";
import { useParams } from "react-router";
import { ReservationDetailEnumsFragment$key } from "@relay-generated/ReservationDetailEnumsFragment.graphql";
import { reservationDetailEnumsFragment } from "./graphql/ReservationDetailEnumsFragment";
import { ReservationForm } from "./ReservationForm";
import { reservationDetailFragment } from "./graphql/ReservationDetailFragment";
import { ReservationDetailFragment$key } from "@relay-generated/ReservationDetailFragment.graphql";
import {
  translateDetailToFormData,
  translateValidFormDataToReservationUpdate,
  ValidReservationUpdateFormData,
} from "./reservationData";
import { reservationUpdateMutation } from "./graphql/ReservationUpdateMutation";
import { useNavigate } from "react-router-dom";
import { reservationUpdateSchemaFactory } from "./reservationSchema";
import { useTranslation } from "h11-client-component-lib";
import { ReservationUpdateMutation } from "@relay-generated/ReservationUpdateMutation.graphql";

export function ReservationUpdatePage() {
  const { uid } = useParams<{ uid: string }>();

  const [queryRef, loadQuery] = useQueryLoader<ReservationUpdateQuery>(
    reservationUpdateQuery,
  );

  useEffect(() => {
    if (uid) {
      loadQuery({ uid }, { fetchPolicy: "network-only" });
    }
  }, [uid]);

  if (uid) {
    return queryRef && <LoadedReservationUpdatePage queryRef={queryRef} />;
  } else {
    return null;
  }
}

function LoadedReservationUpdatePage({
  queryRef,
}: {
  queryRef: PreloadedQuery<ReservationUpdateQuery>;
}) {
  const reservationData = usePreloadedQuery(reservationUpdateQuery, queryRef);

  const enums = useFragment<ReservationDetailEnumsFragment$key>(
    reservationDetailEnumsFragment,
    reservationData,
  );

  const data = useFragment<ReservationDetailFragment$key>(
    reservationDetailFragment,
    reservationData,
  );

  if (!data) {
    // TODO
    return null;
  }

  const reservationMainData = data.reservation;
  const tracesData = data.traces;

  if (!reservationMainData) {
    // TODO
    return null;
  }

  if (!tracesData) {
    // TODO
    return null;
  }

  const navigate = useNavigate();

  const { t } = useTranslation();

  const schema = useMemo(() => reservationUpdateSchemaFactory(t), [t]);

  return (
    <ReservationForm<ValidReservationUpdateFormData, ReservationUpdateMutation>
      data={translateDetailToFormData(reservationMainData, tracesData)}
      enums={enums}
      schema={schema}
      createPrimaryCall={(environment, formData) => {
        const data = translateValidFormDataToReservationUpdate(formData.main);
        return {
          environment,
          mutation: reservationUpdateMutation,
          variables: {
            input: data,
          },
          validateAndExtract: response => {
            const reservationUid = response.reservationUpdate.reservation?.uid;
            return reservationUid
              ? {
                  reservationUid,
                }
              : false;
          },
        };
      }}
      onSuccess={() => {
        navigate("/reservation");
      }}
    />
  );
}
