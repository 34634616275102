import { graphql } from "react-relay";

export const reservationsFragment = graphql`
  fragment ReservationsFragment on Query
  @refetchable(queryName: "ReservationsQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 50 }
    after: { type: "String" }
    filters: { type: ReservationsOverviewFilters }
    orderBy: { type: ReservationsOverviewOrderBy }
  ) {
    reservationsOverviewList(
      first: $first
      after: $after
      filters: { filters: $filters, orderBy: $orderBy }
    )
      @connection(
        key: "ReservationsConnectionFragment_reservationsOverviewList"
      ) {
      edges {
        cursor
        node {
          id
          reservationUid
          status
          group
          tags
          number
          name
          roomType
          roomCount
          guestsCount
          checkIn
          checkOut
          source
          price
          prepayment
          rooming
          orderNumber
          country
          optionDate
          guestCode
          salesCode
          note
          nonRefundable
          author
          createdAt
          cancelledAt
          transactionNumber
          paymentMethod
          attachment
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
