import { useCallback, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  GuestFormData,
  ReservationFormData,
  RoomFormData,
  RoomGroupFormData,
} from "./reservationData";
import { UseFieldArrayReturn, useFormContext, useWatch } from "react-hook-form";
import { useReservationContext } from "./ReservationFormContext";
import { determineGuestChildCategory } from "./reservationUtils";

export const useGuestsCount = () => {
  const { control } = useFormContext<ReservationFormData>();

  // FIXME ověřit, jestli stačí naslouchat změnám groups, protože potřebuji přepočíst i při změně hostů ve všech skupinách
  // Původně bylo řešené přes watch se subscription, což je blbé, protože watch je zlý! useWatch je hodný!
  const groups = useWatch({ control, name: "main.groups" });

  const adultsCount = useMemo(
    // TODO zatím všichni hosté, ale mělo by to být zvlášť dospělí a děti
    () => {
      return groups
        ?.flatMap(g => g?.rooms)
        ?.reduce((acc, room) => acc + (room?.guests?.length ?? 0), 0);
    },
    [groups],
  );

  const childrenCount = 0;

  return {
    adultsCount,
    childrenCount,
    totalCount:
      adultsCount || childrenCount
        ? (adultsCount ?? 0) + (childrenCount ?? 0)
        : undefined,
  };
};

export const useNightsCount = () => {
  const { control } = useFormContext<ReservationFormData>();

  const groups = useWatch({ control, name: "main.groups" });

  return useMemo(() => {
    console.debug("Groups changed");
    const minCheckinDate = groups?.reduce((acc: Dayjs | undefined, group) => {
      if (
        group &&
        group.checkinDate &&
        (!acc || dayjs(group.checkinDate).isBefore(acc))
      ) {
        return dayjs(group.checkinDate);
      }
      return acc;
    }, undefined);
    const maxCheckoutDate = groups?.reduce((acc: Dayjs | undefined, group) => {
      if (
        group &&
        group.checkoutDate &&
        (!acc || dayjs(group.checkoutDate).isAfter(acc))
      ) {
        return dayjs(group.checkoutDate);
      }
      return acc;
    }, undefined);
    if (minCheckinDate && maxCheckoutDate) {
      return maxCheckoutDate.diff(minCheckinDate, "day");
    } else {
      return undefined;
    }
  }, [groups]);
};

export const useReservationGroupDates = (group: RoomGroupFormData) => {
  return useMemo(() => {
    const ret: Dayjs[] = [];
    if (group.checkinDate && group.checkoutDate) {
      let date = dayjs(group.checkinDate);
      const checkoutDate = dayjs(group.checkoutDate);
      while (date.isBefore(checkoutDate)) {
        ret.push(date);
        date = date.add(1, "day");
      }
    }
    return ret;
  }, [group.checkinDate, group.checkoutDate]);
};

function isRoomAnonymous(room: RoomFormData) {
  return (
    (room.guests.length === 0 ||
      room.guests.every(guest => isGuestAnonymous(guest))) &&
    !room.roomId
  );
}

function isGuestAnonymous(guest: GuestFormData) {
  return !guest.firstName && !guest.lastName;
}

export const useRoomsModifier = (
  groupIndex: number,
  roomsFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.rooms"
  >,
  roomsDeletedFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.roomsDeleted"
  >,
) => {
  const { createDefaultRoom, markGroupAsChanged } = useReservationContext();
  return useCallback(
    (newCount: number) => {
      const currentCount = roomsFieldArray.fields.length;
      if (newCount > currentCount) {
        markGroupAsChanged(groupIndex);

        const newRooms = Array.from({ length: newCount - currentCount }, () =>
          createDefaultRoom(),
        );
        roomsFieldArray.replace([...roomsFieldArray.fields, ...newRooms]);
        return 0;
      } else if (newCount < currentCount) {
        markGroupAsChanged(groupIndex);

        const updatedRooms = [...roomsFieldArray.fields];
        const newRoomsDeleted: string[] = [...roomsDeletedFieldArray.fields];

        // Ubíráme pokoje → vybíráme jen dočasně seřazené, ale neměníme pořadí ve výsledku
        const removableRooms = [...updatedRooms]
          .filter(isRoomAnonymous)
          .sort((a, b) => a.guests.length - b.guests.length);

        let remainingToRemove = currentCount - newCount;
        for (const room of removableRooms) {
          if (remainingToRemove <= 0) break;

          const roomIndex = updatedRooms.findIndex(
            r => r.reservationRoomUid === room.reservationRoomUid,
          );
          if (roomIndex !== -1) {
            const removedRoom = updatedRooms.splice(roomIndex, 1)[0];
            remainingToRemove--;

            if (removedRoom.reservationRoomUid) {
              newRoomsDeleted.push(removedRoom.reservationRoomUid);
            }

            // Rozdělení hostů do existujících pokojů
            removedRoom.guests.forEach(guest => {
              const targetRoom = [...updatedRooms].sort(
                (a, b) => a.guests.length - b.guests.length,
              )[0]; // Nejprázdnější pokoj

              if (targetRoom) {
                targetRoom.guests.push(guest);
                targetRoom._updated = true; // Manually set as changed
              }
            });
          }
        }

        roomsFieldArray.replace(updatedRooms);
        roomsDeletedFieldArray.replace(newRoomsDeleted);

        return remainingToRemove;
      } else {
        return 0;
      }
    },
    [roomsFieldArray, createDefaultRoom],
  );
};

export function countGuestsInRoom(
  room: RoomFormData,
  childCategoryId: number | null,
) {
  return room.guests.filter(
    guest => determineGuestChildCategory(guest) === childCategoryId,
  ).length;
}

export const useGuestsModifier = (
  groupIndex: number,
  rooms: RoomFormData[],
  roomsFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.rooms"
  >,
) => {
  const { createDefaultGuest, markGroupAsChanged } = useReservationContext();
  return useCallback(
    (childCategoryId: number | null, newCount: number) => {
      const updatedRooms = [...rooms];

      const currentCount = rooms
        .map(room => countGuestsInRoom(room, childCategoryId))
        .reduce((acc, count) => acc + count, 0);

      if (newCount > currentCount) {
        markGroupAsChanged(groupIndex);

        // We are adding guests based on occupancy (but not changing the order)
        const toAdd = newCount - currentCount;

        for (let i = 0; i < toAdd; i++) {
          const sortedRooms = [...updatedRooms].sort(
            (a, b) => a.guests.length - b.guests.length,
          );
          const targetRoom = sortedRooms[0]; // Least occupied room
          if (targetRoom) {
            targetRoom.guests.push(createDefaultGuest(childCategoryId));
            targetRoom._updated = true; // Manually set as changed
          }
        }
        roomsFieldArray.replace(updatedRooms);
        return 0;
      } else if (newCount < currentCount) {
        markGroupAsChanged(groupIndex);

        // We are removing guests based on occupancy (but not changing the order of rooms)
        let toRemove = currentCount - newCount;

        const sortedRooms = updatedRooms.map((room, originalIndex) => ({
          originalIndex,
          room,
        }));

        function sortRooms() {
          sortedRooms.sort(
            (a, b) => b.room.guests.length - a.room.guests.length,
          );
        }

        sortRooms();

        let i = 0;
        while (i < sortedRooms.length && toRemove > 0) {
          const { originalIndex, room } = sortedRooms[i];
          const removableGuests = room.guests.filter(
            guest =>
              determineGuestChildCategory(guest) === childCategoryId &&
              isGuestAnonymous(guest),
          );

          if (removableGuests.length === 0) {
            ++i;
            continue;
          }

          const lastRemovableGuest =
            removableGuests[removableGuests.length - 1];

          --toRemove;

          const newGuests = room.guests.filter(
            guest => guest !== lastRemovableGuest,
          );

          const newGuestsDeleted = [
            ...(room.guestsDeleted ?? []),
            ...(lastRemovableGuest.uid ? [lastRemovableGuest.uid] : []),
          ];

          const updatedRoom = {
            ...room,
            guests: newGuests,
            guestsDeleted: newGuestsDeleted,
          };
          sortedRooms[i].room = updatedRoom;
          updatedRooms[originalIndex] = updatedRoom;
          updatedRoom._updated = true; // Manually set as changed

          sortRooms();
          i = 0;
        }

        roomsFieldArray.replace([...updatedRooms]);

        return toRemove;
      } else {
        return 0;
      }
    },
    [rooms, roomsFieldArray, createDefaultGuest],
  );
};
