/**
 * @generated SignedSource<<e329f70194ccaedc8a7899d13535d4fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReservationStatusOutput = "cancelled" | "fix" | "tentative" | "unconfirmed" | "waiting" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReservationDetailFragment$data = {
  readonly reservation: {
    readonly checkedInRooms: ReadonlyArray<{
      readonly guests: ReadonlyArray<{
        readonly birthDate: string | null | undefined;
        readonly checkinDate: string | null | undefined;
        readonly childCategoryId: number | null | undefined;
        readonly citizenshipCountry: string | null | undefined;
        readonly firstName: string | null | undefined;
        readonly lastName: string | null | undefined;
        readonly note: string | null | undefined;
        readonly uid: string;
      }>;
      readonly roomId: number | null | undefined;
    }>;
    readonly groupRooms: ReadonlyArray<{
      readonly checkinDate: string;
      readonly checkoutDate: string;
      readonly discountId: number | null | undefined;
      readonly discountNominal: string | null | undefined;
      readonly discountValue: string | null | undefined;
      readonly discountXForY: ReadonlyArray<number> | null | undefined;
      readonly fandbPlanServices: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"FandbPlanServiceFragment">;
      }>;
      readonly pricePlanServices: ReadonlyArray<{
        readonly _id: number;
        readonly cityTaxInPrice: boolean;
        readonly fandbInPrice: boolean;
        readonly fandbPackageId: number | null | undefined;
        readonly packages: ReadonlyArray<{
          readonly amount: number;
          readonly chargeTarget: string;
          readonly chargeType: string;
          readonly description: string | null | undefined;
          readonly note: string | null | undefined;
          readonly packageId: number;
          readonly targetDate: string;
          readonly title: string | null | undefined;
        }>;
        readonly priceListId: number | null | undefined;
        readonly priceMasks: ReadonlyArray<{
          readonly _id: number;
          readonly chargeTarget: string;
          readonly price: string;
          readonly resday: number;
        }>;
      }>;
      readonly roomTypeId: number;
      readonly rooms: ReadonlyArray<{
        readonly aboveAllocations: boolean | null | undefined;
        readonly accountUid: string | null | undefined;
        readonly checkinMessage: string | null | undefined;
        readonly checkoutMessage: string | null | undefined;
        readonly country: string | null | undefined;
        readonly guests: ReadonlyArray<{
          readonly addressCountry: string | null | undefined;
          readonly addressHn: string | null | undefined;
          readonly addressStreet: string | null | undefined;
          readonly addressTown: string | null | undefined;
          readonly addressZip: string | null | undefined;
          readonly birthCountry: string | null | undefined;
          readonly birthDate: string | null | undefined;
          readonly birthName: string | null | undefined;
          readonly birthPlace: string | null | undefined;
          readonly carRegNumber: string | null | undefined;
          readonly checkinDate: string | null | undefined;
          readonly checkinMessage: string | null | undefined;
          readonly checkoutDate: string | null | undefined;
          readonly checkoutMessage: string | null | undefined;
          readonly childCategoryId: number | null | undefined;
          readonly citizenshipCountry: string | null | undefined;
          readonly cityTax: boolean | null | undefined;
          readonly cityTaxExceptionId: number | null | undefined;
          readonly email: string | null | undefined;
          readonly fandbPlanServices: ReadonlyArray<{
            readonly " $fragmentSpreads": FragmentRefs<"FandbPlanServiceFragment">;
          }>;
          readonly firstName: string | null | undefined;
          readonly guestCodeId: number | null | undefined;
          readonly hotelUid: string;
          readonly idCardNumber: string | null | undefined;
          readonly idCardType: string | null | undefined;
          readonly idValidFrom: string | null | undefined;
          readonly idValidTo: string | null | undefined;
          readonly lastName: string | null | undefined;
          readonly loyaltyLevelId: number | null | undefined;
          readonly loyaltyProgram: boolean | null | undefined;
          readonly nameTitleAfter: string | null | undefined;
          readonly nameTitleBefore: string | null | undefined;
          readonly note: string | null | undefined;
          readonly phoneNumber: string | null | undefined;
          readonly plannedCheckoutDate: string | null | undefined;
          readonly profileUid: string | null | undefined;
          readonly purposeOfStayCode: number | null | undefined;
          readonly regCardCurrentAddress: string | null | undefined;
          readonly regCardNote: string | null | undefined;
          readonly reservationRoomUid: string;
          readonly status: string;
          readonly statusChangedBy: string;
          readonly tags: ReadonlyArray<number> | null | undefined;
          readonly uid: string;
          readonly visum1: string | null | undefined;
          readonly visum2: string | null | undefined;
        }>;
        readonly hotelUid: string;
        readonly linkGroupUid: string | null | undefined;
        readonly mainGuestUid: string | null | undefined;
        readonly note: string | null | undefined;
        readonly paymentMethodId: number | null | undefined;
        readonly plannedCheckoutDate: string | null | undefined;
        readonly referenceNumber: string | null | undefined;
        readonly reservationUid: string | null | undefined;
        readonly roomBlockNumber: number | null | undefined;
        readonly roomId: number | null | undefined;
        readonly roomServiceNote: string | null | undefined;
        readonly tags: ReadonlyArray<number> | null | undefined;
        readonly uid: string;
      }>;
      readonly servicePlanServices: ReadonlyArray<{
        readonly _id: number;
        readonly items: ReadonlyArray<{
          readonly chargeType: string;
          readonly complementaryServiceId: number;
          readonly currencyId: number;
          readonly id: number;
          readonly price: string;
          readonly rasterId: number;
          readonly validFrom: string | null | undefined;
          readonly validTo: string | null | undefined;
        }>;
      }>;
      readonly uid: string;
    }>;
    readonly header: {
      readonly accountUid: string | null | undefined;
      readonly arrivalTime: string | null | undefined;
      readonly autoNote: string | null | undefined;
      readonly cancellationPolicyId: number | null | undefined;
      readonly cancellationPolicyText: string | null | undefined;
      readonly cancelledAt: string | null | undefined;
      readonly cancelledByUid: string | null | undefined;
      readonly checkinMessage: string | null | undefined;
      readonly checkoutMessage: string | null | undefined;
      readonly confirmationDate: string | null | undefined;
      readonly country: string | null | undefined;
      readonly createdAt: string;
      readonly createdByUid: string;
      readonly groupUid: string | null | undefined;
      readonly guestCodeId: number | null | undefined;
      readonly hotelUid: string;
      readonly isGroup: boolean | null | undefined;
      readonly new: boolean | null | undefined;
      readonly nonRefundable: boolean | null | undefined;
      readonly note: string | null | undefined;
      readonly orderAddress: string | null | undefined;
      readonly orderContact: string | null | undefined;
      readonly orderEmail: string | null | undefined;
      readonly orderName: string | null | undefined;
      readonly orderNumber: string | null | undefined;
      readonly orderPhoneNumber: string | null | undefined;
      readonly originId: number | null | undefined;
      readonly parentGroupUid: string | null | undefined;
      readonly preferredPaymentMethodId: number | null | undefined;
      readonly profileUid: string | null | undefined;
      readonly rateCategoryId: number | null | undefined;
      readonly referenceCode: string | null | undefined;
      readonly referenceNumber: string | null | undefined;
      readonly reservationName: string;
      readonly reservationNumber: number;
      readonly reservationUid: string;
      readonly roomServiceNote: string | null | undefined;
      readonly saleCodeId: number | null | undefined;
      readonly sourceId: number;
      readonly status: ReservationStatusOutput;
      readonly statusChangedAt: string;
      readonly statusChangedBy: string;
      readonly tags: ReadonlyArray<number> | null | undefined;
      readonly updatedAt: string | null | undefined;
      readonly updatedByUid: string | null | undefined;
    } | null | undefined;
    readonly uid: string;
  } | null | undefined;
  readonly traces: ReadonlyArray<{
    readonly _id: number;
    readonly assignedGroupUid: string | null | undefined;
    readonly assignedUserUid: string | null | undefined;
    readonly createdAt: string;
    readonly createdBy: string;
    readonly description: string | null | undefined;
    readonly important: boolean;
    readonly reservationRoomUid: string | null | undefined;
    readonly solution: string | null | undefined;
    readonly status: string;
    readonly targetDate: string;
    readonly title: string;
    readonly tracesCategoryId: number | null | undefined;
    readonly updatedAt: string;
    readonly updatedBy: string;
  }>;
  readonly " $fragmentType": "ReservationDetailFragment";
};
export type ReservationDetailFragment$key = {
  readonly " $data"?: ReservationDetailFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReservationDetailFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "reservationUid",
  "variableName": "uid"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountUid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkinMessage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkoutMessage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "guestCodeId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hotelUid",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileUid",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reservationUid",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roomServiceNote",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusChangedBy",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roomId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthDate",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "citizenshipCountry",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "childCategoryId",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkinDate",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkoutDate",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "FandBPlanService",
  "kind": "LinkedField",
  "name": "fandbPlanServices",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FandbPlanServiceFragment"
    }
  ],
  "storageKey": null
},
v27 = {
  "alias": "_id",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chargeTarget",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chargeType",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetDate",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedCheckoutDate",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reservationRoomUid",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "uid"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReservationDetailFragment",
  "selections": [
    {
      "alias": "reservation",
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "Reservation",
      "kind": "LinkedField",
      "name": "reservationGet",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ReservationHeader",
          "kind": "LinkedField",
          "name": "header",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "arrivalTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "autoNote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cancellationPolicyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cancellationPolicyText",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cancelledAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cancelledByUid",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmationDate",
              "storageKey": null
            },
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdByUid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "groupUid",
              "storageKey": null
            },
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isGroup",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "new",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nonRefundable",
              "storageKey": null
            },
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderAddress",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderContact",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderPhoneNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "originId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "parentGroupUid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferredPaymentMethodId",
              "storageKey": null
            },
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rateCategoryId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "referenceCode",
              "storageKey": null
            },
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reservationName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reservationNumber",
              "storageKey": null
            },
            (v12/*: any*/),
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "saleCodeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sourceId",
              "storageKey": null
            },
            (v14/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "statusChangedAt",
              "storageKey": null
            },
            (v15/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "updatedByUid",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ReservationRoom",
          "kind": "LinkedField",
          "name": "checkedInRooms",
          "plural": true,
          "selections": [
            (v18/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ReservationGuest",
              "kind": "LinkedField",
              "name": "guests",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v21/*: any*/),
                (v22/*: any*/),
                (v23/*: any*/),
                (v24/*: any*/),
                (v9/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GroupRooms",
          "kind": "LinkedField",
          "name": "groupRooms",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roomTypeId",
              "storageKey": null
            },
            (v24/*: any*/),
            (v25/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discountId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discountNominal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discountValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discountXForY",
              "storageKey": null
            },
            (v26/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PricePlanService",
              "kind": "LinkedField",
              "name": "pricePlanServices",
              "plural": true,
              "selections": [
                (v27/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cityTaxInPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fandbInPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fandbPackageId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PricePlanPackage",
                  "kind": "LinkedField",
                  "name": "packages",
                  "plural": true,
                  "selections": [
                    (v28/*: any*/),
                    (v9/*: any*/),
                    (v29/*: any*/),
                    (v30/*: any*/),
                    (v31/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "packageId",
                      "storageKey": null
                    },
                    (v32/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "priceListId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PricePlanPriceMask",
                  "kind": "LinkedField",
                  "name": "priceMasks",
                  "plural": true,
                  "selections": [
                    (v27/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "resday",
                      "storageKey": null
                    },
                    (v33/*: any*/),
                    (v28/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ServicePlanService",
              "kind": "LinkedField",
              "name": "servicePlanServices",
              "plural": true,
              "selections": [
                (v27/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ServicePlanItem",
                  "kind": "LinkedField",
                  "name": "items",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    (v33/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "validTo",
                      "storageKey": null
                    },
                    (v29/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "complementaryServiceId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "currencyId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rasterId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "validFrom",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GroupedReservationRoom",
              "kind": "LinkedField",
              "name": "rooms",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v18/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mainGuestUid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReservationGuest",
                  "kind": "LinkedField",
                  "name": "guests",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v19/*: any*/),
                    (v20/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    (v21/*: any*/),
                    (v22/*: any*/),
                    (v23/*: any*/),
                    (v9/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "loyaltyProgram",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "addressCountry",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "addressHn",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "addressStreet",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "addressTown",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "addressZip",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "birthCountry",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "birthName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "birthPlace",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "carRegNumber",
                      "storageKey": null
                    },
                    (v24/*: any*/),
                    (v3/*: any*/),
                    (v25/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cityTax",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cityTaxExceptionId",
                      "storageKey": null
                    },
                    (v26/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "idCardNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "idCardType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "idValidFrom",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "idValidTo",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "loyaltyLevelId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "nameTitleAfter",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "nameTitleBefore",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "phoneNumber",
                      "storageKey": null
                    },
                    (v34/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "purposeOfStayCode",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "regCardCurrentAddress",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "regCardNote",
                      "storageKey": null
                    },
                    (v35/*: any*/),
                    (v14/*: any*/),
                    (v15/*: any*/),
                    (v16/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "visum1",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "visum2",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "aboveAllocations",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "linkGroupUid",
                  "storageKey": null
                },
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentMethodId",
                  "storageKey": null
                },
                (v34/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "roomBlockNumber",
                  "storageKey": null
                },
                (v13/*: any*/),
                (v16/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "traces",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "status",
          "value": "open"
        }
      ],
      "concreteType": "Trace",
      "kind": "LinkedField",
      "name": "traceFilteredList",
      "plural": true,
      "selections": [
        (v27/*: any*/),
        (v30/*: any*/),
        (v32/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "important",
          "storageKey": null
        },
        (v35/*: any*/),
        (v14/*: any*/),
        (v31/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "solution",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignedGroupUid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignedUserUid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tracesCategoryId",
          "storageKey": null
        },
        (v17/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedBy",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f08cef4c74acedd64ac2f118b45ae0f1";

export default node;
