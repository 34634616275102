import { ErrorMessage } from "@hookform/error-message";
import * as React from "react";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types/path";
import "./FormErrorMessage.scss";

export const FormErrorMessage = <TFieldValues extends FieldValues>({
  name,
}: {
  name: FieldPath<TFieldValues>;
}) => {
  return (
    <ErrorMessage
      name={name}
      render={({ message }) => (
        <div className="FormErrorMessage">{message}</div>
      )}
    />
  );
};
