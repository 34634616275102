import dayjs from "dayjs";
import { GuestFormData } from "./reservationData";

// Returns guest child category or null if it cannot be determined or the guest is an adult
export function determineGuestChildCategory(
  guest: GuestFormData,
): number | null;
export function determineGuestChildCategory(
  birthDate: string | null | undefined,
  childCategoryId: number | null | undefined,
): number | null;

export function determineGuestChildCategory(
  guestOrBirthDate: GuestFormData | string | null | undefined,
  childCategoryId?: number | null,
): number | null {
  // Získáme birthDate a childCategoryId z objektu nebo parametrů
  const birthDate =
    typeof guestOrBirthDate === "object"
      ? guestOrBirthDate?.birthDate
      : guestOrBirthDate;

  const categoryId =
    typeof guestOrBirthDate === "object"
      ? guestOrBirthDate?.childCategoryId
      : childCategoryId;

  // Pokud není datum narození, vracíme childCategoryId (nebo null)
  if (!birthDate) return categoryId ?? null;

  // Vypočítáme věk
  const age = dayjs().diff(dayjs(birthDate), "year");

  // Určíme kategorii podle věku
  return age < 4 ? 1 : age < 12 ? 2 : age < 18 ? 3 : null;
}
