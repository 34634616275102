import { createContext, useContext } from "react";
import {
  GuestFormData,
  ReservationEnums,
  ReservationFormData,
  RoomFormData,
} from "./reservationData";
import { UseFieldArrayReturn } from "react-hook-form";

export const ReservationFormContext = createContext<{
  enums: ReservationEnums;
  nextGroupTempUid: () => string;
  nextRoomTempUid: () => string;
  nextGuestTempUid: () => string;
  createDefaultGuest: (childCategoryId: number | null) => GuestFormData;
  createDefaultRoom: () => RoomFormData;

  // No need to call these mark*AsChanged when simple field changes, only when adding/removing from to fieldArrays
  markGroupAsChanged: (groupIndex: number) => void;
  markRoomAsChanged: (groupIndex: number, roomIndex: number) => void;
  markGuestAsChanged: (
    groupIndex: number,
    roomIndex: number,
    guestIndex: number,
  ) => void;

  nextTraceTempId: () => number;
  tracesFieldArray: UseFieldArrayReturn<ReservationFormData, "traces">;
  tracesDeletedFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "tracesDeleted"
  >;
} | null>(null);

export const useReservationContext = () => {
  const context = useContext(ReservationFormContext);
  if (context === null) {
    throw new Error("No reservation context");
  }
  return context;
};
