import { ReservationFormData } from "../../reservationData";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import * as React from "react";
import { GuestRow } from "./GuestRow";
import { Button, useTranslation } from "h11-client-component-lib";
import { useReservationContext } from "../../ReservationFormContext";
import { CaretRightFill, PlusCircle, Trash } from "react-bootstrap-icons";
import { RoomHeaderRow } from "./RoomHeaderRow";
import { useDroppable } from "@dnd-kit/core";
import { clsx } from "clsx";
import { FormErrorMessage } from "../../../to_lib/FormErrorMessage";

export function RoomRows({
  groupIndex,
  roomIndex,
  onDelete,
}: {
  groupIndex: number;
  roomIndex: number;
  onDelete?: () => void;
}) {
  const { t } = useTranslation();
  const reservationContext = useReservationContext();
  const { control } = useFormContext<ReservationFormData>();
  const room = useWatch({
    control,
    name: `main.groups.${groupIndex}.rooms.${roomIndex}`,
    exact: true,
  });
  const guestsFieldArray = useFieldArray({
    control,
    name: `main.groups.${groupIndex}.rooms.${roomIndex}.guests`,
  });
  const guestsDeletedFieldArray = useFieldArray({
    control,
    name: `main.groups.${groupIndex}.rooms.${roomIndex}.guestsDeleted`,
  });

  console.debug("RoomRows render");

  const { isOver, setNodeRef: setDroppableRef } = useDroppable({
    id: room.reservationRoomUid + "/LAST",
  });

  return (
    <>
      <RoomHeaderRow
        key={"room-header-" + roomIndex}
        roomIndex={roomIndex}
        groupIndex={groupIndex}
      />
      {guestsFieldArray.fields.map((g, index) => {
        return (
          <GuestRow
            key={g.id}
            guestIndex={index}
            roomIndex={roomIndex}
            groupIndex={groupIndex}
            main={index === 0}
            onDelete={() => {
              guestsFieldArray.remove(index);
              if (g.uid) {
                guestsDeletedFieldArray.append(g.uid);
              }
              reservationContext.markRoomAsChanged(groupIndex, roomIndex);
            }}
          />
        );
      })}
      <tr
        ref={setDroppableRef}
        className={clsx("new-row", { "is-over": isOver })}>
        <td colSpan={11}>
          <div className="drop-pointer">
            <CaretRightFill />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              height: "100%",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 12,
              }}>
              <FormErrorMessage
                name={`groups.${groupIndex}.rooms.${roomIndex}.guests`}
              />
              <Button
                icon={<PlusCircle />}
                variant="textual"
                onClick={() => {
                  guestsFieldArray.append(
                    reservationContext.createDefaultGuest(null),
                  );
                  reservationContext.markRoomAsChanged(groupIndex, roomIndex);
                }}>
                {t("add_guest")}
              </Button>
            </div>
            {guestsFieldArray.fields.length === 0 && (
              <Button
                variant="secondary"
                icon={<Trash />}
                style={{ alignSelf: "center" }}
                onClick={onDelete}>
                {t("delete_room")}
              </Button>
            )}
          </div>
        </td>
      </tr>
    </>
  );
}
