import { useEffect, useRef, useState } from "react";
import { ItemsListHandle } from "../ItemsList";
import {
  FilterAndHighlightCompareMode,
  StringKeyOfValueType,
  useHighlightAndFilter,
} from "h11-client-component-lib";

export interface UseAutocompleteProps<ItemType extends object> {
  value: ItemType | undefined;
  onChange: (item: ItemType | undefined) => void;
  items: readonly ItemType[];
  filterFields: StringKeyOfValueType<ItemType, string | undefined | null>[];
  compareMode?: FilterAndHighlightCompareMode;
  numberOnly?: boolean;
  renderTextValue: (item: ItemType) => string;
}

export const useAutocomplete = <ItemType extends object>({
  value,
  onChange,
  items,
  filterFields,
  compareMode = "includes",
  numberOnly,
  renderTextValue,
}: UseAutocompleteProps<ItemType>) => {
  const [inputValue, setInputValue] = useState("");
  const listRef = useRef<ItemsListHandle>(null);

  const [filterAndHighlight, setFilterAndHighlight] = useState(false);

  useEffect(() => {
    if (value) {
      setInputValue(renderTextValue(value));
    }
  }, [value]);

  // TODO potom udělat nějaký autocomplete s tabulkou

  const itemsHighlighted = useHighlightAndFilter({
    items,
    search: inputValue,
    fields: filterFields,
    filter: filterAndHighlight,

    // Explicitly turned off highlighting for numbers only
    highlight: filterAndHighlight && !numberOnly,

    compareMode,
  });

  const findExactMatch = (highlightedItems: ItemType[]) => {
    return highlightedItems.find(i => renderTextValue(i) === inputValue);
  };

  useEffect(() => {
    // TODO shouldn't we pass the original item to the onChange instead of the highlighted one?
    const exactMatchHighlighted = findExactMatch(itemsHighlighted);
    if (exactMatchHighlighted) {
      onChange(exactMatchHighlighted);
    }
  }, [itemsHighlighted]);

  useEffect(() => {
    listRef.current?.scrollToSelected();
  }, [value]);

  return {
    itemsHighlighted,
    inputValue,
    setInputValue: (a: string) => {
      setFilterAndHighlight(true);
      setInputValue(a);
      if (value && renderTextValue(value) !== a) {
        onChange(undefined);
      }
    },
    setFilterAndHighlight,
    onConfirm: () => {
      const exactMatchHighlighted = findExactMatch(itemsHighlighted);
      if (exactMatchHighlighted) {
        onChange(exactMatchHighlighted);
        return 1;
      } else if (itemsHighlighted.length === 1) {
        onChange(itemsHighlighted[0]);
      } else if (itemsHighlighted.length > 0) {
        listRef.current?.focus();
      }
      return itemsHighlighted.length;
    },
    resetInputValue: () => {
      setInputValue(value ? renderTextValue(value) : "");
    },
    menuRef: listRef,
  };
};
