import { useTranslation } from "h11-client-component-lib";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ReservationFormData } from "../reservationData";
import { RoomRows } from "./RoomRows";

export function RoomsTable() {
  const { t } = useTranslation();

  const { control } = useFormContext<ReservationFormData>();

  const rooms = useFieldArray({ control, name: "main.checkedInRooms" });

  return (
    <div className="Table RoomsTable">
      <table>
        <thead>
          <tr>
            <th style={{ width: 80 }}>{t("room")}</th>
            <th style={{ width: 0 }}></th>
            <th>{t("last_name")}</th>
            <th>{t("first_name")}</th>
            <th>{t("birth_date")}</th>
            <th>{t("citizenship")}</th>
            <th>{t("checkin")}</th>
            <th>{t("note_abbreviation")}</th>
            <th style={{ width: 0 }}></th>
            <th style={{ width: 0 }}></th>
          </tr>
        </thead>
        <tbody>
          {rooms.fields.map((room, index) => (
            <RoomRows key={room.id} roomIndex={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
