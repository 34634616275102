import { useTranslation } from "h11-client-component-lib";
import * as React from "react";
import { useCallback, useState } from "react";
import {
  GuestFormData,
  ReservationFormData,
  RoomFormData,
} from "../../reservationData";
import { UseFieldArrayReturn, useFormContext } from "react-hook-form";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { RoomRows } from "./RoomRows";
import { GuestRow } from "./GuestRow";
import { useReservationContext } from "../../ReservationFormContext";

interface DraggedObject extends TargetObject {
  guestIndex: number;
  guest: GuestFormData;
}

interface TargetObject {
  roomIndex: number;
  room: RoomFormData;
  guestIndex: number | "LAST";
  guest: GuestFormData | "LAST";
}

export function RoomsTable({
  groupIndex,
  roomsFieldArray,
  roomsDeletedFieldArray,
}: {
  groupIndex: number;
  roomsFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.rooms"
  >;
  roomsDeletedFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.roomsDeleted"
  >;
}) {
  const { t } = useTranslation();
  const reservationContext = useReservationContext();

  const [draggedObject, setDraggedObject] = useState<DraggedObject>();

  const { getValues } = useFormContext<ReservationFormData>();

  console.debug("RoomsTable render");

  const findDraggedObject = useCallback(
    <T extends boolean>(
      target: T,
      itemId: string,
    ): T extends true
      ? TargetObject | undefined
      : DraggedObject | undefined => {
      const rooms = getValues(`main.groups.${groupIndex}.rooms`);
      const [activeRoomId, activeGuestId] = itemId.split("/");
      const roomIndex = rooms.findIndex(
        r => r.reservationRoomUid === activeRoomId,
      );
      const room = rooms[roomIndex];
      if (activeGuestId === "LAST" && target) {
        return {
          roomIndex,
          room,
          guestIndex: "LAST",
          guest: "LAST",
        } as T extends true ? TargetObject : never;
      } else {
        const guestIndex = room?.guests.findIndex(g => g.uid === activeGuestId);
        const guest = room?.guests[guestIndex];
        if (room && guest) {
          return { roomIndex, room, guestIndex, guest };
        } else {
          return undefined;
        }
      }
    },
    [getValues, groupIndex],
  );

  const onDragStart = ({ active }: DragStartEvent) => {
    const draggedObject = findDraggedObject(false, active.id as string);
    setDraggedObject(draggedObject);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    try {
      if (!over) {
        return;
      }

      const activeObject = findDraggedObject(false, active.id as string);
      const overObject = findDraggedObject(true, over.id as string);

      if (activeObject && overObject) {
        if (
          activeObject.room.reservationRoomUid !==
          overObject.room.reservationRoomUid
        ) {
          // Different room
          {
            // Source update
            const newGuests = [
              ...getValues(
                `main.groups.${groupIndex}.rooms.${activeObject.roomIndex}.guests`,
              ),
            ];

            const newGuestsDeleted = [
              ...(getValues(
                `main.groups.${groupIndex}.rooms.${activeObject.roomIndex}.guestsDeleted`,
              ) ?? []),
              ...(activeObject.guest.uid ? [activeObject.guest.uid] : []),
            ];

            newGuests.splice(activeObject.guestIndex, 1);
            roomsFieldArray.update(activeObject.roomIndex, {
              ...activeObject.room,
              guests: newGuests,
              guestsDeleted: newGuestsDeleted,
              _updated: true,
            });
          }

          {
            // Target update
            const newGuests = [
              ...getValues(
                `main.groups.${groupIndex}.rooms.${overObject.roomIndex}.guests`,
              ),
            ];

            // Removal of UID from deleted is not necessary, because the guest is deleted and recreated

            const newGuest = {
              ...activeObject.guest,
              uid: reservationContext.nextGuestTempUid(),
              _updated: true,
            } satisfies GuestFormData;

            if (overObject.guestIndex === "LAST") {
              newGuests.push(newGuest);
            } else {
              newGuests.splice(overObject.guestIndex, 0, newGuest);
            }
            roomsFieldArray.update(overObject.roomIndex, {
              ...overObject.room,
              guests: newGuests,
              _updated: true,
            });
          }
        } else {
          // Same room
          const newGuests = [
            ...getValues(
              `main.groups.${groupIndex}.rooms.${activeObject.roomIndex}.guests`,
            ),
          ];

          const sourceIndex = activeObject.guestIndex;

          newGuests.splice(activeObject.guestIndex, 1);

          if (overObject.guestIndex === "LAST") {
            newGuests.push({
              ...activeObject.guest,
              _updated: true,
            });
          } else {
            let targetIndex = overObject.guestIndex;

            // Index correction when the source object (removed) is before the target object (inserted)
            if (sourceIndex < targetIndex) {
              --targetIndex;
            }

            newGuests.splice(targetIndex, 0, {
              ...activeObject.guest,
              _updated: true,
            });
          }

          roomsFieldArray.update(overObject.roomIndex, {
            ...overObject.room,
            guests: newGuests,
            _updated: true,
          });
        }
      }
    } finally {
      setDraggedObject(undefined);
    }

    reservationContext.markGroupAsChanged(groupIndex);
  };

  const sensors = useSensors(useSensor(PointerSensor));
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}>
      <div className="Table RoomsTable">
        <table>
          <thead>
            <tr>
              <th style={{ width: 80 }}>{t("room")}</th>
              <th style={{ width: 0 }}></th>
              <th style={{ width: 0 }}></th>
              <th>{t("last_name")}</th>
              <th>{t("first_name")}</th>
              <th>{t("email")}</th>
              <th>{t("birth_date")}</th>
              <th>{t("citizenship")}</th>
              <th>{t("note_abbreviation")}</th>
              <th style={{ width: 0 }}></th>
              <th style={{ width: 0 }}></th>
              <th style={{ width: 0 }}></th>
            </tr>
          </thead>
          <tbody>
            {roomsFieldArray.fields.map((room, index) => (
              <RoomRows
                key={room.id}
                groupIndex={groupIndex}
                roomIndex={index}
                onDelete={() => {
                  roomsFieldArray.remove(index);
                  if (room.reservationRoomUid) {
                    roomsDeletedFieldArray.append(room.reservationRoomUid);
                  }
                  reservationContext.markGroupAsChanged(groupIndex);
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
      <DragOverlay>
        {draggedObject ? (
          <div className="HoresApp" style={{ width: 300 }}>
            <table className="Table RoomsTable">
              <tbody>
                <GuestRow
                  groupIndex={groupIndex}
                  roomIndex={draggedObject.roomIndex}
                  guestIndex={draggedObject.guestIndex}
                  forDrag
                  main={false}
                />
              </tbody>
            </table>
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
