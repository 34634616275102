// helper method
import * as yup from "yup";
import dayjs from "dayjs";

//region Přidání převodu datumu do formátu pro API

declare module "yup" {
  interface StringSchema {
    toApiDate(): this;
    toApiDateTime(): this;
  }
}

yup.addMethod(yup.string, "toApiDate", function () {
  return this.transform(value => {
    return value ? dayjs(value).format("YYYY-MM-DD") : value;
  });
});

yup.addMethod(yup.string, "toApiDateTime", function () {
  return this.transform(value => {
    return value ? dayjs(value).toISOString() : value;
  });
});

//endregion

// Maybe one day we can create our own File schema validation: https://github.com/jquense/yup/blob/master/docs/extending.md#creating-new-schema-types
