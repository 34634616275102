import * as React from "react";
import { Switch, SwitchProps } from "h11-client-component-lib";
import { Controller } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { useCommonFieldProps } from "./formHooks";
import { FormFieldProps } from "./FormField";

export type FormSwitchFieldProps<TFieldValues extends FieldValues> = Omit<
  SwitchProps,
  "value" | "name" | "onChange"
> &
  FormFieldProps<TFieldValues>;

export function FormSwitchField<T extends FieldValues>({
  field,
  control,
  ...props
}: FormSwitchFieldProps<T>) {
  const commonFieldProps = useCommonFieldProps(control, field);

  return (
    <Controller
      name={field}
      control={control}
      render={({ field: fieldObj }) => (
        <Switch
          name={field}
          checked={fieldObj.value}
          onChange={fieldObj.onChange}
          {...props}
          {...commonFieldProps}
        />
      )}
    />
  );
}
