import * as React from "react";
import { TextField, TextFieldProps } from "h11-client-component-lib";
import { Controller } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { useCommonFieldProps } from "./formHooks";
import { FormFieldProps } from "./FormField";

export type FormTextFieldProps<TFieldValues extends FieldValues> = Omit<
  TextFieldProps,
  "value" | "name" | "onChange"
> &
  FormFieldProps<TFieldValues>;

export function FormTextField<T extends FieldValues>({
  field,
  control,
  ...props
}: FormTextFieldProps<T>) {
  const { commonFieldProps } = useCommonFieldProps(control, field);

  return (
    <Controller
      name={field}
      control={control}
      render={({ field: fieldObj }) => {
        return (
          <TextField
            name={field}
            value={fieldObj.value}
            onChange={fieldObj.onChange}
            onBlur={() => {
              fieldObj.onBlur();
            }}
            {...props}
            {...commonFieldProps}
          />
        );
      }}
    />
  );
}
