import {
  forwardRef,
  ReactNode,
  RefObject,
  useImperativeHandle,
  useRef,
} from "react";
import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import { BasicElementProps, useTranslation } from "h11-client-component-lib";
import "./ItemsList.scss";
import { clsx } from "clsx";

export interface ItemsProps<ItemType extends object> {
  getId: (v: ItemType) => string | number;
  items: readonly ItemType[];
  renderValue: (value: ItemType) => ReactNode;
}

export interface ItemsListProps<ItemType extends object>
  extends ItemsProps<ItemType>,
    BasicElementProps {
  selectedItemId?: string | number;
  onItemSelected: (value: ItemType) => void;
  listRef?: RefObject<ItemsListHandle>; // listRef instead of ref, because of the generic params
}

export interface ItemsListHandle {
  focus: () => void;
  scrollToSelected: () => void;
}

export const ItemsList = <ItemType extends object>({
  items,
  renderValue,
  getId,
  onItemSelected,
  selectedItemId,
  listRef,
  ...restProps
}: ItemsListProps<ItemType>) => {
  const { t } = useTranslation();
  const firstItemRef = useRef<HTMLAnchorElement>(null);
  const componentItemsListRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(listRef, () => ({
    focus: () => {
      firstItemRef.current?.focus();
    },
    scrollToSelected: () => {
      componentItemsListRef.current
        ?.querySelector(".selected")
        ?.scrollIntoView({ block: "start" });
    },
  }));
  return (
    <ComponentItemsList ref={componentItemsListRef} {...restProps}>
      {items.length > 0 ? (
        items.map((i, index) => (
          <ComponentItem
            key={getId(i)}
            selected={getId(i) === selectedItemId}
            ref={index === 0 ? firstItemRef : undefined}
            className={index === 0 ? "first" : undefined}
            onClick={() => {
              onItemSelected(i);
            }}>
            {renderValue(i)}
          </ComponentItem>
        ))
      ) : (
        <ComponentItem className="no-items" disabled>
          {t("no_items")}
        </ComponentItem>
      )}
    </ComponentItemsList>
  );
};

export interface ComponentItemsListProps extends BasicElementProps {
  children: ReactNode;
}

export const ComponentItemsList = forwardRef<
  HTMLDivElement,
  ComponentItemsListProps
>(({ children, className, ...restProps }, ref) => (
  <div className={clsx("ItemsList", className)} ref={ref} {...restProps}>
    <div className="scroller">{children}</div>
  </div>
));
ComponentItemsList.displayName = "ComponentItemsList";

export interface ComponentItemProps extends BasicElementProps {
  onClick?: () => void;
  children: ReactNode;
  disabled?: boolean;
  selected?: boolean;
}

export const ComponentItem = forwardRef<HTMLAnchorElement, ComponentItemProps>(
  ({ onClick, children, disabled, selected, className, ...restProps }, ref) => {
    return (
      <BootstrapDropdown.Item
        {...restProps}
        className={clsx("ComponentItem", className, { selected })}
        ref={ref}
        disabled={disabled}
        onClick={onClick}>
        <div className="content">{children}</div>
      </BootstrapDropdown.Item>
    );
  },
);
ComponentItem.displayName = "ComponentItem";
