import * as React from "react";
import dayjs from "dayjs";
import { DateField, DateFieldProps } from "h11-client-component-lib";
import { Controller } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { useCommonFieldProps } from "./formHooks";
import { FormFieldProps } from "./FormField";

export type FormDateFieldProps<TFieldValues extends FieldValues> = Omit<
  DateFieldProps,
  "value" | "name" | "onChange"
> &
  FormFieldProps<TFieldValues>;

export function FormDateField<TFieldValues extends FieldValues>({
  control,
  field,
  ...props
}: FormDateFieldProps<TFieldValues>) {
  const { commonFieldProps } = useCommonFieldProps(control, field);

  return (
    <Controller
      name={field}
      control={control}
      render={({ field: fieldObj }) => {
        return (
          <DateField
            name={field}
            value={fieldObj.value ? dayjs(fieldObj.value) : undefined}
            onChange={fieldObj.onChange}
            {...props}
            {...commonFieldProps}
          />
        );
      }}
    />
  );
}
