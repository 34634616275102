import { FlowLayout, useTranslation } from "h11-client-component-lib";
import { FormTextField } from "../../../to_lib/FormTextField";
import { useFormContext } from "react-hook-form";
import { ReservationFormData } from "../../reservationData";

export function OrderedByPanel() {
  const { t } = useTranslation();
  const { control } = useFormContext<ReservationFormData>();
  return (
    <FlowLayout>
      <FormTextField
        control={control}
        field="main.header.orderName"
        label={t("ordered_by")}
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1 }}
      />
      <FormTextField
        control={control}
        field="main.header.orderNumber"
        label={t("order_number")}
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1 }}
      />
      <FormTextField
        control={control}
        field="main.header.orderEmail"
        label={t("email")}
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1 }}
      />
      <FormTextField
        control={control}
        field="main.header.orderPhoneNumber"
        label={t("phone_number")}
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1 }}
      />
      <FormTextField
        control={control}
        field="main.header.orderAddress"
        label={t("address")}
        multiline
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1, minHeight: 100 }}
      />
      <FormTextField
        control={control}
        field="main.header.orderContact"
        label={t("note")}
        multiline
        style={{ flexBasis: 300, minWidth: 300, flexGrow: 1 }}
      />
    </FlowLayout>
  );
}
