import { forwardRef, KeyboardEvent, useImperativeHandle, useRef } from "react";
import { InputHandle, useBetterDropdown } from "./BetterDropdown";
import { TextField, TextFieldProps } from "h11-client-component-lib";
import { ChevronDown } from "react-bootstrap-icons";

interface InputFieldDropdownToggleProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  onEnter?: (e: KeyboardEvent<HTMLElement>) => void;
  selectAllOnFocus?: boolean;
  numberOnly?: boolean;
  TextFieldProps?: Partial<TextFieldProps>;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const InputFieldDropdownToggle = forwardRef<
  InputHandle,
  InputFieldDropdownToggleProps
>(
  (
    {
      value,
      label,
      onChange,
      numberOnly,
      onEnter,
      selectAllOnFocus,
      TextFieldProps,
      onFocus,
      onBlur,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));
    const betterDropdown = useBetterDropdown();

    const preventClick = useRef(false);

    return (
      <TextField
        {...TextFieldProps}
        ref={inputRef}
        label={label}
        value={value}
        onChange={(text, e) => {
          if (text && numberOnly) {
            const number = Number(text);
            if (Number.isFinite(number)) {
              onChange(number?.toString());
            }
          } else {
            onChange(numberOnly ? "" : text);
          }
        }}
        onClick={() => {
          if (!preventClick.current) {
            betterDropdown.changeOpen(!betterDropdown.isOpen);
          }
        }}
        endAdornment={<ChevronDown />}
        onFocus={() => {
          preventClick.current = true;
          setTimeout(() => {
            preventClick.current = false;
          }, 100);
          if (selectAllOnFocus) {
            inputRef.current?.select();
          }
          betterDropdown.changeOpen(true);
          onFocus?.();
        }}
        onBlur={onBlur}
        onKeyDown={e => {
          switch (e.key) {
            case "ArrowDown":
            case "ArrowUp":
              betterDropdown.changeOpen(true);
              break;
            case "Escape":
              betterDropdown.changeOpen(false);
              break;
            case "Enter":
              onEnter?.(e);
              break;
          }
        }}
      />
    );
  },
);
InputFieldDropdownToggle.displayName = "TextFieldDropdownToggle";
