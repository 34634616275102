import { ReservationFormData } from "../../reservationData";
import { UseFieldArrayReturn } from "react-hook-form";
import { forwardRef, useCallback, useMemo, useRef } from "react";
import { termsAndRoomTypesSectionHeadersWidths } from "../TermsAndRoomTypesSection";
import {
  NumberField,
  useAppContext,
  useTranslation,
} from "h11-client-component-lib";
import {
  AutocompleteField,
  AutocompleteFieldHandle,
} from "../../../to_lib/dropdown/autocomplete/AutocompleteField";
import { useRoomsModifier } from "../../reservationHooks";

// As array of objects, because autocomplete requires it for highlighting
const roomsCountOptions = [...Array(199)].map((_, i) => ({
  num: i + 1,
  label: (i + 1).toString(),
}));

interface RoomsCountFieldProps {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
}

const RoomsCountField = forwardRef<HTMLElement, RoomsCountFieldProps>(
  ({ value, onChange }, ref) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 20,
        }}>
        <NumberField
          ref={ref}
          value={value}
          onChange={onChange}
          variant="buttoned"
          fieldWidth={40}
          style={{ textAlign: "center" }}
        />
      </div>
    );
  },
);
RoomsCountField.displayName = "RoomsCountField";

export const RoomsCountSelectField = ({
  groupIndex,
  roomsFieldArray,
  roomsDeletedFieldArray,
}: {
  groupIndex: number;
  roomsFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.rooms"
  >;
  roomsDeletedFieldArray: UseFieldArrayReturn<
    ReservationFormData,
    "main.groups.0.roomsDeleted"
  >;
}) => {
  const { t } = useTranslation();
  const { notify } = useAppContext();

  const roomsCount = useMemo(
    () => roomsFieldArray.fields.length ?? 0,
    [roomsFieldArray.fields],
  );

  const roomsModifier = useRoomsModifier(
    groupIndex,
    roomsFieldArray,
    roomsDeletedFieldArray,
  );

  const autocompleteRef = useRef<AutocompleteFieldHandle>(null);

  const handleChange = useCallback(
    (newCount: number) => {
      const notRemovedCount = roomsModifier(newCount);
      if (notRemovedCount) {
        notify(
          t("some_rooms_could_not_be_removed", {
            count: notRemovedCount,
          }),
          "warning",
        );
        autocompleteRef.current?.resetValue();
      }
    },
    [roomsModifier, roomsCount],
  );

  return (
    <AutocompleteField
      autocompleteRef={autocompleteRef}
      label={t("rooms_count")}
      items={roomsCountOptions}
      getId={i => i.num}
      renderValue={i => i.labelHighlighted}
      renderTextValue={i => i.label}
      onChange={i => handleChange(i?.num ?? 0)}
      value={roomsCountOptions.find(i => i.num === roomsCount)}
      filterFields={["label"]}
      compareMode="startsWith"
      commitMode="on-close"
      numberOnly
      TextFieldProps={{
        fieldWidth: "autosize",
        inputStyle: { minWidth: termsAndRoomTypesSectionHeadersWidths[2] - 38 },
      }}
    />
  );
};
