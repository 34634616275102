import * as yup from "yup";
import dayjs from "dayjs";
import { ReservationStatusInput } from "@relay-generated/ReservationUpdateMutation.graphql";

/*const fandbPlanServiceSchema = z.object({
  chargeType: z.string(),
  fandbPackageId: z.number(),
  items: z.array(
    z.object({
      centerId: z.number(),
      currencyId: z.number(),
      fandbCodeId: z.number(),
      note: z.string(),
      price: z.string(),
      resday: z.number(),
    }),
  ),
});

export const reservationSchema = z.object({
  groups: z
    .array(
      z.object({
        _updated: z.boolean().nullish(),
        uid: z.string(), // Can't be null. When new, local temporary uid is set
        checkinDate: z.string(),
        checkoutDate: z.string(),
        discountId: z.number().nullish(),
        discountNominal: z.string().nullish(),
        discountValue: z.string().nullish(),
        discountXForY: z.array(z.number()).nullish(),
        fandbPlanService: fandbPlanServiceSchema.nullish(),
        pricePlanService: z
          .array(
            z.object({
              items: z.array(
                z.object({
                  amount: z.number(),
                  chargeTarget: z.string(),
                  chargeType: z.string(),
                  currencyId: z.number(),
                  description: z.string(),
                  discountId: z.number(),
                  discountNominal: z.string(),
                  discountValue: z.string(),
                  finalPrice: z.string(),
                  itemType: z.string(),
                  locked: z.boolean(),
                  note: z.string(),
                  origin: z.string(),
                  packageId: z.number(),
                  rasterId: z.number(),
                  targetDate: z.string(),
                  title: z.string(),
                  unitPrice: z.string(),
                }),
              ),
              packages: z.array(
                z.object({
                  amount: z.number(),
                  chargeTarget: z.string(),
                  chargeType: z.string(),
                  description: z.string(),
                  note: z.string(),
                  packageId: z.number(),
                  targetDate: z.string(),
                  title: z.string(),
                }),
              ),
              priceMask: z.array(
                z.object({
                  chargeTarget: z.string(),
                  price: z.string(),
                  resday: z.number(),
                }),
              ),
              pricelistId: z.number(),
            }),
          )
          .nullish(),
        roomTypeId: z.number(),
        rooms: z.array(
          z.object({
            _updated: z.boolean().nullish(),
            reservationRoomUid: z.string(), // Can't be null. When new, local temporary uid is set
            aboveAllocations: z.boolean().nullish(),
            accountUid: z.string().nullish(),
            checkinMessage: z.string().nullish(),
            checkoutMessage: z.string().nullish(),
            country: z.string().nullish(),
            guests: z.array(
              z.object({
                _updated: z.boolean().nullish(),
                addressCountry: z.string().nullish(),
                addressHn: z.string().nullish(),
                addressStreet: z.string().nullish(),
                addressTown: z.string().nullish(),
                addressZip: z.string().nullish(),
                birthCountry: z.string().nullish(),
                birthDate: z.string().nullish(),
                birthName: z.string().nullish(),
                birthPlace: z.string().nullish(),
                carRegNumber: z.string().nullish(),
                checkinDate: z.string().nullish(),
                checkinMessage: z.string().nullish(),
                checkoutDate: z.string().nullish(),
                checkoutMessage: z.string().nullish(),
                childCategoryId: z.number().nullish(),
                citizenshipCountry: z.string().nullish(),
                cityTax: z.boolean().nullish(),
                cityTaxExceptionId: z.number().nullish(),
                email: z.string().nullish(),
                fandbPlanService: fandbPlanServiceSchema.nullish(),
                firstName: z.string().nullish(),
                guestCodeId: z.number().nullish(),
                idCardNumber: z.string().nullish(),
                idCardType: z.string().nullish(),
                idValidFrom: z.string().nullish(),
                idValidTo: z.string().nullish(),
                lastName: z.string().nullish(),
                loyaltyLevelId: z.number().nullish(),
                loyaltyProgram: z.boolean(),
                note: z.string().nullish(),
                profileUid: z.string().nullish(),
                purposeOfStayCode: z.number().nullish(),
                regCardCurrentAddress: z.string().nullish(),
                regCardNote: z.string().nullish(),
                tags: z.array(z.number()).nullish(),
                uid: z.string().nullish(), // Can't be null. When new, local temporary uid is set
                visum1: z.string().nullish(),
                visum2: z.string().nullish(),
              }),
            ),
            guestsDeleted: z.array(z.string()).nullish(),
            note: z.string().nullish(),
            paymentMethodId: z.number().nullish(),
            plannedCheckoutDate: z.string().nullish(),
            referenceNumber: z.string().nullish(),
            roomBlockNumber: z.number().nullish(),
            roomId: z.number().nullish(),
            roomServiceNote: z.string().nullish(),
            tags: z.array(z.number()).nullish(),
          }),
        ),
        roomsDeleted: z.array(z.string()).nullish(),
        servicePlan: z
          .array(
            z.object({
              chargeOnce: z.boolean(),
              chargeType: z.string(),
              complementaryServiceId: z.number(),
              currencyId: z.number(),
              price: z.string(),
              rasterId: z.number(),
              validFrom: z.string(),
              validTo: z.string(),
            }),
          )
          .nullish(),
      }),
    )
    .default([]),
  groupsDeleted: z.array(z.string()).nullish(),
  header: z.object({
    accountUid: z.string().nullish(),
    arrivalTime: z.string().nullish(),
    autoNote: z.string().nullish(),
    cancellationPolicyId: z.number().nullish(),
    cancellationPolicyText: z.string().nullish(),
    checkinMessage: z.string().nullish(),
    checkoutMessage: z.string().nullish(),
    confirmationDate: z.string().nullish(),
    country: z.string().nullish(),
    groupUid: z.string().nullish(),
    guestCodeId: z.number().nullish(),
    isGroup: z.boolean().nullish(),
    nonRefundable: z.boolean().nullish(),
    note: z.string().nullish(),
    orderAddress: z.string().nullish(),
    orderContact: z.string().nullish(),
    orderEmail: z.string().nullish(),
    orderName: z.string().nullish(),
    orderNumber: z.string().nullish(),
    orderPhoneNumber: z.string().nullish(),
    originId: z.number().nullish(),
    parentGroupUid: z.string().nullish(),
    preferredPaymentMethodId: z.number().nullish(),
    profileUid: z.string().nullish(),
    referenceCode: z.string().nullish(),
    referenceNumber: z.string().nullish(),
    reservationName: z.string().nonempty(),
    roomServiceNote: z.string().nullish(),
    saleCodeId: z.number().nullish(),
    sourceId: z.number(),
    tags: z.array(z.number()).nullish(),
    status: z.string(), // TODO enum?
  }),
  uid: z.string().nullable(),
  checkedInRooms: z.any(), // TODO pryč z ReservationFormData i odsud
});

export const reservationUpdateSchema = reservationSchema.extend({
  uid: z.string(),
});*/

// FIXME měl bych vyřešit, že undefined nebudou existovat (možná i ve schématu relaye?) - RHF potřebuje kompletní objekty
// FIXME celé dělat přes nějaký preprocesor, který vychází z GraphQL - vygenerovat schéma, typy, defaultní hodnoty
// TODO translate funkci udělat obecnou
const reservationMainSchemaFactory = (t: (key: string) => string) =>
  yup
    .object({
      groups: yup
        .array(
          yup.object({
            _updated: yup.boolean().nullable(),
            uid: yup.string().nullable(), // Can't be null. When new, local temporary uid is set
            checkinDate: yup.string().toApiDate().required(),
            checkoutDate: yup
              .string()
              .toApiDate()
              .required()
              .test(
                "endDateIsLaterThanStartDate",
                () => t("end_date_must_be_later_than_start_date"),
                (checkoutDate, context) => {
                  const checkinDate = context.parent.checkinDate;
                  if (checkinDate && checkoutDate) {
                    return dayjs(checkoutDate).isAfter(dayjs(checkinDate));
                  }
                },
              ),
            discountId: yup.number().nullable(),
            discountNominal: yup.string().nullable(),
            discountValue: yup.string().nullable(),
            discountXForY: yup
              .array(yup.number().required().nonNullable())
              .nullable(),
            //fandbPlanService: fandbPlanServiceSchema.nullable(),
            /*pricePlanService: z
          .array(
            yup.object({
              items: yup.array(
                yup.object({
                  amount: yup.number().nullable(),
                  chargeTarget: yup.string().nullable(),
                  chargeType: yup.string().nullable(),
                  currencyId: yup.number().nullable(),
                  description: yup.string().nullable(),
                  discountId: yup.number().nullable(),
                  discountNominal: yup.string().nullable(),
                  discountValue: yup.string().nullable(),
                  finalPrice: yup.string().nullable(),
                  itemType: yup.string().nullable(),
                  locked: yup.boolean().nullable(),
                  note: yup.string().nullable(),
                  origin: yup.string().nullable(),
                  packageId: yup.number().nullable(),
                  rasterId: yup.number().nullable(),
                  targetDate: yup.string().nullable(),
                  title: yup.string().nullable(),
                  unitPrice: yup.string().nullable(),
                }).nullable(),
              ).nullable(),
              packages: yup.array(
                yup.object({
                  amount: yup.number().nullable(),
                  chargeTarget: yup.string().nullable(),
                  chargeType: yup.string().nullable(),
                  description: yup.string().nullable(),
                  note: yup.string().nullable(),
                  packageId: yup.number().nullable(),
                  targetDate: yup.string().nullable(),
                  title: yup.string().nullable(),
                }).nullable(),
              ).nullable(),
              priceMask: yup.array(
                yup.object({
                  chargeTarget: yup.string().nullable(),
                  price: yup.string().nullable(),
                  resday: yup.number().nullable(),
                }).nullable(),
              ).nullable(),
              pricelistId: yup.number().nullable(),
            }).nullable(),
          )
          .nullable(),*/
            roomTypeId: yup.number().required(),
            rooms: yup
              .array(
                yup.object({
                  _updated: yup.boolean().nullable(),
                  reservationRoomUid: yup.string().nullable(), // Can't be null. When new.nullable(), local temporary uid is set
                  aboveAllocations: yup.boolean().nullable(),
                  //accountUid: yup.string().nullable(),
                  checkinMessage: yup.string().nullable(),
                  checkoutMessage: yup.string().nullable(),
                  country: yup.string().nullable(),
                  guests: yup
                    .array(
                      yup.object({
                        _updated: yup.boolean().nullable(),
                        addressCountry: yup.string().nullable(),
                        addressHn: yup.string().nullable(),
                        addressStreet: yup.string().nullable(),
                        addressTown: yup.string().nullable(),
                        addressZip: yup.string().nullable(),
                        birthCountry: yup.string().nullable(),
                        birthDate: yup.string().nullable().toApiDate(),
                        birthName: yup.string().nullable(),
                        birthPlace: yup.string().nullable(),
                        carRegNumber: yup.string().nullable(),
                        checkinDate: yup.string().nullable(),
                        checkinMessage: yup.string().nullable(),
                        checkoutDate: yup.string().nullable(),
                        checkoutMessage: yup.string().nullable(),
                        childCategoryId: yup.number().nullable().nullable(),
                        citizenshipCountry: yup.string().nullable(),
                        cityTax: yup.boolean().nullable(),
                        cityTaxExceptionId: yup.number().nullable(),
                        email: yup.string().nullable().email(),
                        //fandbPlanService: fandbPlanServiceSchema.nullable(),
                        firstName: yup.string().nullable(),
                        guestCodeId: yup.number().nullable(),
                        idCardNumber: yup.string().nullable(),
                        idCardType: yup.string().nullable(),
                        idValidFrom: yup.string().nullable(),
                        idValidTo: yup.string().nullable(),
                        lastName: yup.string().nullable(),
                        loyaltyLevelId: yup.number().nullable(),
                        loyaltyProgram: yup.boolean().nonNullable(),
                        note: yup.string().nullable(),
                        profileUid: yup.string().nullable(),
                        purposeOfStayCode: yup.number().nullable(),
                        regCardCurrentAddress: yup.string().nullable(),
                        regCardNote: yup.string().nullable(),
                        tags: yup.array(yup.number().required()),
                        uid: yup.string().nullable(), // Can't be null. When new.nullable(), local temporary uid is set
                        visum1: yup.string().nullable(),
                        visum2: yup.string().nullable(),
                      }),
                    )
                    .min(1, t("at_least_one_guest_is_required"))
                    .required(),
                  guestsDeleted: yup.array(yup.string().required()).nullable(),
                  note: yup.string().nullable(),
                  paymentMethodId: yup.number().nullable(),
                  plannedCheckoutDate: yup.string().nullable(),
                  referenceNumber: yup.string().nullable(),
                  //roomBlockNumber: yup.number().nullable(),
                  roomId: yup.number().nullable(),
                  roomServiceNote: yup.string().nullable(),
                  tags: yup.array(yup.number().required()),
                }),
              )
              .min(1, t("at_least_one_room_is_required"))
              .required(),
            roomsDeleted: yup.array(yup.string().required()).nullable(),
            servicePlan: yup
              .array(
                yup.object({
                  chargeOnce: yup.boolean(),
                  chargeType: yup.string().required(),
                  complementaryServiceId: yup.number().required(),
                  currencyId: yup.number().required(),
                  price: yup.string().required(),
                  rasterId: yup.number().required(),
                  validFrom: yup.string().required(),
                  validTo: yup.string().required(),
                }),
              )
              .nullable(),
          }),
        )
        .min(1)
        .required(),
      groupsDeleted: yup.array(yup.string().required()).nullable(),
      header: yup
        .object({
          accountUid: yup.string().nullable(),
          arrivalTime: yup.string().nullable(),
          autoNote: yup.string().nullable(),
          cancellationPolicyId: yup.number().nullable(),
          cancellationPolicyText: yup.string().nullable(),
          checkinMessage: yup.string().nullable(),
          checkoutMessage: yup.string().nullable(),
          confirmationDate: yup.string().nullable(),
          country: yup.string().nullable(),
          groupUid: yup.string().nullable(),
          guestCodeId: yup.number().nullable(),
          isGroup: yup.boolean().nullable(),
          nonRefundable: yup.boolean().nullable(),
          note: yup.string().nullable(),
          orderAddress: yup.string().nullable(),
          orderContact: yup.string().nullable(),
          orderEmail: yup.string().nullable(),
          orderName: yup.string().nullable(),
          orderNumber: yup.string().nullable(),
          orderPhoneNumber: yup.string().nullable(),
          originId: yup.number().nullable(),
          parentGroupUid: yup.string().nullable(),
          preferredPaymentMethodId: yup.number().nullable(),
          profileUid: yup.string().nullable(),
          rateCategoryId: yup.number().nullable(),
          referenceCode: yup.string().nullable(),
          referenceNumber: yup.string().nullable(),
          reservationName: yup.string().required(),
          roomServiceNote: yup.string().nullable(),
          saleCodeId: yup.number().nullable(),
          sourceId: yup.number().required(),
          tags: yup.array(yup.number().required()).nullable(),
          status: yup
            .string()
            .oneOf([
              "tentative",
              "unconfirmed",
              "waiting",
              "fix",
            ] satisfies ReservationStatusInput[])
            .nullable(),
        })
        .required(),
      uid: yup.string().nullable(),
      checkedInRooms: yup.mixed(), // TODO pryč z ReservationFormData i odsud
    })
    .required();

export const traceSchemaFactory = (t: (key: string) => string) =>
  yup.object({
    id: yup.number().nullable(),
    title: yup.string().required(),
    targetDate: yup.string().toApiDateTime().required(),
    important: yup.boolean().required(),
    reservationRoomUid: yup.string().nullable(),
    solution: yup.string().nullable(),
    assignedGroupUid: yup.string().nullable(),
    assignedUserUid: yup.string().nullable(),
    //tracesCategoryId:yup.number().nullable(),
    status: yup.string().required().default("open"),
    description: yup.string().nullable(),
    createdAt: yup.string(),
    createdBy: yup.string(),
    updatedAt: yup.string(),
    updatedBy: yup.string(),
    _updated: yup.boolean().nullable(),
  });

export const reservationSchemaFactory = (t: (key: string) => string) =>
  yup.object({
    main: reservationMainSchemaFactory(t),
    tracesDeleted: yup
      // RHF doesn't support useFieldArray with simple types (although it works on guestsDeleted for example...)
      .array(yup.object({ id: yup.number().required() }).required())
      .nullable(),
    traces: yup.array(traceSchemaFactory(t)).required(),
  });

export const reservationUpdateSchemaFactory = (t: (key: string) => string) => {
  return reservationSchemaFactory(t).concat(
    yup.object({
      main: reservationMainSchemaFactory(t).shape({
        uid: yup.string().required(),
      }),
    }),
  );
};

// TODO zrevidovat, včetně přidávání nové group, room, guest, protože tam nedávám kompletní strukturu. Nevím, jestli to RHF vyžaduje? V default values stopro ano
/*export function defaultValuesFromSchema<T extends z.ZodTypeAny>(
  schema: T,
): z.infer<T> {
  if (schema instanceof z.ZodObject) {
    const shape = schema.shape as ZodRawShape;
    return Object.entries(shape).reduce((acc, [key, value]) => {
      acc[key as keyof z.infer<typeof schema>] = defaultValuesFromSchema(value);
      return acc;
    }, {} as z.infer<T>);
  }

  if (schema instanceof z.ZodArray) {
    return [] as z.infer<T>;
  }

  if (schema instanceof z.ZodString) {
    return schema.isOptional() || schema.isNullable()
      ? null
      : ("" as z.infer<T>);
  }

  if (schema instanceof z.ZodNumber) {
    return schema.isOptional() || schema.isNullable()
      ? null
      : (0 as z.infer<T>);
  }

  if (schema instanceof z.ZodBoolean) {
    return schema.isOptional() || schema.isNullable()
      ? null
      : (false as z.infer<T>);
  }

  if (schema instanceof z.ZodNullable) {
    return null as z.infer<T>;
  }

  if (schema instanceof z.ZodDefault) {
    return schema._def.defaultValue() as z.infer<T>;
  }

  if (schema instanceof z.ZodUnion) {
    return defaultValuesFromSchema(schema.options[0]) as z.infer<T>;
  }

  if (schema instanceof z.ZodEffects) {
    return defaultValuesFromSchema(schema.innerType()) as z.infer<T>;
  }

  if (schema instanceof z.ZodEnum) {
    return schema.options[0] as z.infer<T>;
  }

  if (schema instanceof z.ZodLiteral) {
    return schema.value as z.infer<T>;
  }

  return null as z.infer<T>; // Fallback for unhandled types
}*/

// TODO třeba ještě nějak promyslet - možná nějakej preproces?
// TODO povinné položky se dají z jistit z insertu, protože tam je potřeba opravdu poslat všechny, které jsou povinné - v update ne, protože co se nepošle se nezaktualizuje
// FIXME dát někam do utils

/*type DeepNonNullable<T> = T extends (infer U)[]
  ? DeepNonNullable<U>[]
  : T extends object
    ? { [K in keyof T]: NonNullable<DeepNonNullable<T[K]>> }
    : NonNullable<T>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isSubtype = <_T extends U, U>(): void => {};

isSubtype<
  DeepNonNullable<z.infer<typeof reservationSchema>>,
  ReservationFormData
>();

type g = {
  c: {
    a: string | undefined;
  };
};

type h = DeepOmitNullish<g>;

const a: h = {
  c: {
    a: undefined,
  },
};

isSubtype<{ c: { a: string; b: number } }, h>();*/
