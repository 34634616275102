import {
  IconButton,
  Panel,
  PanelProps,
  useTranslation,
} from "h11-client-component-lib";
import {
  Children,
  ComponentType,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import { ChevronDown } from "react-bootstrap-icons";
import "./CollapsiblePanel.scss";
import { clsx } from "clsx";

type ChildType =
  | ReactElement<HeaderProps, typeof Header>
  | ReactElement<ContentProps, typeof Content>;

export interface CollapsiblePanelProps extends PanelProps {
  children: ChildType | ChildType[];
}

type CollapsiblePanelType = FC<CollapsiblePanelProps> & {
  Header: ComponentType<HeaderProps>;
  Content: ComponentType<ContentProps>;
};

export const CollapsiblePanel: CollapsiblePanelType = ({
  children,
  ...restProps
}: CollapsiblePanelProps) => {
  const [collapsed, setCollapsed] = useState(false);

  const { t } = useTranslation();
  return (
    <Panel
      {...restProps}
      className={clsx("CollapsiblePanel", restProps.className)}>
      <div className="top">
        {Children.toArray(children).filter(
          c => isValidElement(c) && c.type === Header,
        )}
        <IconButton
          className="collapse-button"
          onClick={() => setCollapsed(c => !c)}
          variant="outlined"
          tooltip={
            collapsed ? t("expand_panel_tooltip") : t("collapse_panel_tooltip")
          }
          size={32}>
          <ChevronDown
            style={{ transform: collapsed ? "rotate(0deg)" : "rotate(180deg)" }}
          />
        </IconButton>
      </div>
      {!collapsed &&
        Children.toArray(children).filter(
          c => isValidElement(c) && c.type === Content,
        )}
    </Panel>
  );
};

export interface HeaderProps {
  children: ReactNode;
}

export const Header = ({ children }: HeaderProps) => {
  return <div className="CollapsiblePanelHeader">{children}</div>;
};

export interface ContentProps {
  children: ReactNode;
}

export const Content = ({ children }: ContentProps) => {
  return <div className="Content">{children}</div>;
};

CollapsiblePanel.Header = Header;
CollapsiblePanel.Content = Content;
